body {
  font-family: "Century Gothic" !important;
  margin: 0px;
}

footer {
  height: 200px;
  background: #2b2b2b;
  padding: 28px;
}

#main-image {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding: 30px;
}
.home-container {
  top: 0;
  position: absolute;
  display: contents;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.turquoise {
  color: #3bd8c9;
}

#main-races {
  min-height: 80vh;
  position: relative;
  min-height: 670px;
}

#menu {
  float: right;
  width: calc(100% - 240px);
}

#left-menu {
  float: left;
}

#right-menu {
  float: right;
}

#main-inner-text {
  text-align: center;
  font-size: 50px;
  width: 600px;
  color: #272727;
  font-weight: bold;
}

.button-container {
  width: 100%;
  text-align: center;
}

#next-gp-div .button-container {
  margin: 15px 0px;
}

.margintopx1 {
  margin-top: 20px;
}

.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#social-media div {
  width: 70%;
  max-width: 800px;
  font-size: 43px;
}

#social-media div a {
  color: #cccccc;
}

#social-media div i:hover {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.fa-youtube:hover {
  color: #fe0000;
}

.fa-twitter:hover {
  color: #179cf0;
}

.fa-instagram:hover {
  color: rgb(209, 65, 144);
}

.fa-discord:hover {
  color: #5865f2;
}
.fa-twitch:hover {
  color: #9146ff;
}

#support {
  text-align: center;
  margin-top: 20px;
}

#support a {
  color: #cccccc;
  text-decoration: none;
}

#support > a:hover {
  color: #e7e7e7 !important;
}

#copyright-message {
  text-align: center;
  color: #cccccc;
  margin-top: 20px;
}

#main-data {
  height: 200px;
  background: #ececec;
}

#main-data > div {
  width: 130px;
  text-align: center;
}

#main-data > div > span {
  width: 100%;
  display: block;
}

#main-data > div > span:first-child {
  font-size: 30px;
  font-weight: bold;
}

#main-data > div > span:nth-child(2) {
  font-size: 20px;
}

#secondary-image {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 30vh;
  width: 100%;
}

#secondary-image span {
  font-size: 50px;
  font-weight: bold;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

#main-info {
  min-height: 40vh;
  padding: 8vh 0px;
}

#main-info span {
  font-weight: normal;
}

#info-container p {
  font-weight: bold;
  font-size: 32px;
  color: black;
}

#info-container {
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  text-align: justify;
  color: #303030;
}
#info-container svg {
  font-size: 50px;
  color: #3bd8c9;
}
#info-container .row:first-child {
  margin-bottom: 8vh;
}

#info-container .row > div {
  padding-right: 40px;
  padding-left: 40px;
}

.mr-auto {
  margin-right: auto !important;
}

.btn-primary {
  background-color: #1dc9b8 !important;
  border: #0a6d63 !important;
}

.btn-primary:hover {
  background-color: #1bc0b0 !important;
}
.dropdown-menu {
  margin-top: 12px !important;
  right: 0 !important;
  border: 0px !important;
  border-radius: 0px !important;
}
.dropdown-menu a {
  padding: 9px 14px;
  color: white !important;
}

.dropdown-menu a:active {
  background: #1dc9b8 !important;
}

.dropdown-menu a:hover,
.dropdown-menu a:focus {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.error {
  color: #f50000;
}

.form-switch input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #4d4d4d !important;
  border-color: #4d4d4d !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  outline: 0 none !important;
}

.form-switch .form-check-input:focus:not(:checked) {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23bfbfbf%27/></svg>") !important;
}

#calendar-div {
  width: 530px;
}
#next-gp-div {
  width: 53%;
}

#lastgp-standings {
  min-height: 100vh;
  padding-top: 64px;
}

#next-gp-standings {
  width: calc(100% - 530px);
}

#last-gp-div {
  width: 50%;
}

#last-gp-results {
  width: 300px;
  padding-top: 0px;
  z-index: 1;
}

.team-bar {
  width: 5px;
  height: 14px;
  margin-right: 3px;
  margin-top: 3px;
  margin-left: 3px;
  border: 1px solid black;
}

.last-gp-driver {
  margin-bottom: 2px;
  font-size: 14px;
}

.last-gp-driver > span {
  width: 20px;
  text-align: right;
}

.aux-last-gp-span {
  width: 30px !important;
  text-align: center !important;
}
#positions-container {
  max-height: calc(76vh - 144px);
  overflow: hidden;
}

.standings-team {
  width: 100%;
  height: 26px;
  position: relative;
  overflow: hidden;
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
}

.standings-team img {
  width: 60px;
}
.logo-container {
  width: 85px;
  height: 85px;
  position: absolute;
  top: -31px;
  left: -2px;
}

.standings-team a {
  position: absolute;
  left: 92px;
  font-size: 14px;
  margin-top: 3px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.teamdriver-container {
  width: calc(100% - 100px);
  height: 42px;
  position: relative;
  overflow: hidden;
  max-width: 535px;
}

.results-team {
  width: 100%;
  height: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}
.results-team img {
  width: 30px;
}
.logo-results-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  left: 10px;
}
.teamdriver-results-container {
  width: 100%;
  height: 20px;
  position: relative;
  padding-left: 37px;
}
.teamdriver-results-container a,
.teamdriver-results-container label {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  display: block;
  padding-right: 4px;
}
.results-position {
  width: 20px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 14px;
  padding: 0px 14px;
}

.standings-points {
  width: 68px;
  margin-left: 3px;
  font-weight: bold;
  font-size: 14px;
}

.standings-position {
  width: 41px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 14px;
}

.standings-container {
  height: 145px;
  overflow: hidden;
}

.class-container {
  position: relative;
  width: 100%;
  padding: 5px;
}

.scroll-button {
  position: absolute;
  width: 21px;
  border: 0px;
  border-radius: 20px;
  left: calc(50% - 17px);
  z-index: 1;
  font-size: 12px;
}

.scroll-button:hover {
  transition: 1s;
}

.scroll-up {
  top: -9px;
}
.scroll-down {
  bottom: 17px;
}

.general-standings-container {
  margin-bottom: 25px;
}

#positions-container > p {
  font-weight: bold;
}

.info-race {
  margin-top: 16px;
  margin-bottom: 10px;
  font-size: 14px;
}

.info-race svg {
  color: #3bd8c9;
}

.corner-borders {
  position: relative;
  /*padding-bottom: 8px;*/
  height: 100%;
}
.corner-borders:before,
.corner-borders:after,
.corner-borders > :first-child:before,
.corner-borders > :first-child:after {
  position: absolute;
  width: 124px;
  height: 140px;
  border-style: solid; /* or whatever style */
  content: " ";
}
.corner-borders:before {
  top: 0;
  left: 0;
  border-width: 4px 0 0 4px;
}
.corner-borders:after {
  top: 0;
  right: 0;
  border-width: 4px 4px 0 0;
}
.corner-borders > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 4px 4px 0;
}
.corner-borders > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 4px 4px;
}

#next-gp-standings > div {
  width: 100%;
}

#last-gp-results a {
  display: block !important;
}

.empty-pos {
  opacity: 0;
}

.mycanvas-container .button-red {
  z-index: 1;
}

.react-calendar__viewContainer .day-selected abbr {
  color: black !important;
}

#images-index-container img {
  width: 100%;
}

#images-index-container div:first-child {
  height: calc(80vh - 85px);
  margin-bottom: 5px;
}
#images-index-container div:last-child {
  height: 20vh;
}

#index-container {
  min-height: 100vh;
}
#index-container > div {
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
#index-container > div:first-child {
  width: 70%;
}
#index-container > div:last-child {
  width: 40%;
}

.navbar {
  height: 64px;
  z-index: 20;
}
.categoryWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.categoryWrapper:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a29ca9+0,95909b+99 */
  background: #a29ca9; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #a29ca9 0%,
    #95909b 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #a29ca9 0%,
    #95909b 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #a29ca9 0%,
    #95909b 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a29ca9', endColorstr='#95909b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.9s ease 0s;
}

.categoryWrapper:hover:after {
  opacity: 0.4;
}

.categoryWrapper h1 {
  font-size: 50px;
  letter-spacing: 2px;
  -webkit-transition: all 0.15s ease 0s;
  position: relative;
  z-index: 10;
  font-size: 4vw;
}

.categoryWrapper:hover h1 {
  transform: translateY(-10px);
}

.categoryWrapper button {
  position: absolute;
  transform: translatey(60px);
  -webkit-appearance: none;
  border: none;
  background: none;
  font-size: 20px;
  padding: 0;
  margin: 0;
  outline: none;
  z-index: 10;
}

.categoryWrapper button span {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 3vw;
}

.categoryWrapper button > span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 0;

  border: 1px solid white;
  border-left: none;
  border-bottom: none;

  transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
}

.categoryWrapper:hover button > span:after {
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
}

.categoryWrapper button > span:before {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  width: 20px;
  height: 0;

  border: 1px solid white;
  border-right: none;
  border-bottom: none;

  transition: height 0.15s ease-out, width 0.15s ease-out 0.15s;
}

.categoryWrapper:hover button > span:before {
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
}

.categoryWrapper button > span > span:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0%;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.categoryWrapper:hover button > span > span:before {
  opacity: 1;
  border-bottom: 1px solid white;
  width: calc(50%);
  height: 1px;
  transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
}

.categoryWrapper button > span > span:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.categoryWrapper:hover button > span > span:after {
  opacity: 1;
  border-bottom: 1px solid white;
  width: calc(50%);
  height: 1px;
  transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.3s;
}

.categoryWrapper:hover button > span > span > span {
  color: white;
}

.categoryWrapper button > span > span > span {
  padding: 1vw 2vw;
  transition: color 0.15s ease-out 0s;
}

.categoryWrapper button:hover > span > span > span:after {
  padding: 1vw 2vw;
}
.categoryWrapper button:hover > span > span > span:after {
  width: 100%;
}

.categoryWrapper button > span > span > span:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: attr(data-attr-span);
  width: 0%;
  height: 100%;
  background: white;
  white-space: nowrap;
  text-align: center;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transition: width 0.2s;
}

.categoryWrapper:hover button > span > span > span,
.categoryWrapper button > span > span > span:hover {
  color: white;
}
.hidden {
  display: none;
}

.standings-class-container {
  display: flex;
  justify-content: space-between;
  min-height: 180px;
}

.no-data-yet {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

/* MOBILE STYLES */
@media (max-width: 1280px) {
  #main-info p {
    font-size: 24px;
    margin-bottom: 0px !important;
  }
  #info-container svg {
    font-size: 26px;
  }
  #info-container > div > div {
    margin-bottom: 15px;
  }
  #info-container .row:first-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 1100px) {
  #last-gp-div,
  #next-gp-standings,
  #calendar-div,
  #next-gp-div {
    width: 100%;
  }
  #lastgp-standings,
  #main-races {
    display: inline-block;
    width: 100%;
  }
}

@media (max-width: 762px) {
  .mycanvas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  .milestone-driver {
    display: none;
  }
  #countdown {
    margin-top: 0px !important;
  }
  #circuit-last-gp {
    width: 100% !important;
    text-align: center;
    margin-bottom: 10px;
  }
  #countdown .flex {
    justify-content: center;
  }
  #main-inner-text,
  #secondary-image span {
    font-size: 24px !important;
  }
  .title1,
  #main-data > div > span:first-child {
    font-size: 20px !important;
  }
  .title2,
  #main-data > div > span:nth-child(2) {
    font-size: 16px !important;
  }
  .button-lvf1-front {
    padding: 5px 10px;
    font-size: 15px;
  }
  #social-media div a {
    font-size: 32px;
  }
  .standings-team a {
    left: 13px;
  }
  .logo-container {
    left: auto;
    right: 10px;
  }
  .standings-points,
  .standings-position {
    font-size: 20px;
  }
  .standings-points {
    width: 66px;
  }
  .standings-position {
    width: 46px;
  }
  .white-driver,
  #positions-container p {
    font-size: 16px;
  }
  #positions-container > div:nth-child(n + 7) {
    display: none;
  }
  .mycanvas .button-red {
    top: 8px;
  }
  #last-gp-results {
    width: 100%;
  }
}
