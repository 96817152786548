.mycanvas {
  top: 0;
  left: 0;
  position: relative;
}

#layout-last-gp-container {
  position: relative;
  height: 300px;
  width: calc(100% - 300px);
}

#circuit-last-gp {
  width: 275px;
  padding: 0px 20px;
  margin-top: 5px;
  position: absolute;
  top: 54px;
}

.title1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
}
.title2 {
  text-align: center;
  font-size: 24px;
}

.title1 .flag {
  height: 20px;
  margin-top: -4px;
}

.milestone-driver {
  margin-bottom: 30px;
  align-items: baseline;
}

.milestone-driver p {
  margin-bottom: 0px;
}

.countdown-title {
  font-size: 9px;
}

.countdown-days {
  margin-right: 5px;
  padding: 2px;
}

.countdown-title {
  width: 51px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.countdown-value {
  font-size: 20px;
  font-weight: bold;
}

#countdown p {
  margin-bottom: 0px;
  text-align: center;
}
.errorMessage {
  color: #aa0725 !important;
  position: absolute;
}

#last-gp-layout-container .mycanvas {
  height: 65vh; /* 50% of NextGP component */
}
#last-gp-layout-container .mycanvas-container {
  width: calc(100% - 300px);
  height: 65vh; /* 50% of parent */
}

#last-gp-layout-container .button-red,
#gp-results-layout-container .button-red {
  display: none;
}
