#race-entries-container {
  width: 100%;
  position: relative;
}
#race-entries-left {
  margin-right: 5%;
}
#race-entries-right {
  width: 290px;
  text-align: center;
}
#race-entries-container .mycanvas {
  height: 300px;
}
#race-entries-container .mycanvas .button-red {
  display: none;
}

#weather-container .rw-container {
  width: 650px;
  font-size: 12px;
}
#date-entry > b {
  margin-right: 5px;
}
.rw-container-left,
.icon-0-2-34,
.icon-0-2-26 {
  padding: 5px !important;
}

.rw-today-hr {
  margin: 0px !important;
}

.current-0-2-11 {
  font-size: 30px !important;
}
.desc-0-2-32 {
  margin: 0px 0px !important;
}
.day-0-2-30 {
  margin: 0px 0px !important;
}
.header-0-2-3 {
  margin: 0px 0px 5px !important;
}

#date-entry p {
  margin-left: 3px;
  margin-top: 3px;
}

.current-0-2-11,
.current-0-2-9 {
  font-size: 24px !important;
}
#driver-entries-container {
  display: inline-block;
  width: 100%;
}

#driver-entries-container p,
#reserve-drivers-container p {
  border-radius: 10px 10px 0px 0px;
}
#driver-entries-container > div:last-child,
#reserve-drivers-container > div:last-child {
  border-radius: 0px 0px 10px 10px;
}

#driver-entries-container img {
  max-height: 50px;
  max-width: 100%;
}
.num-driver {
  display: flex;
}
.num-driver span {
  width: 32px;
  font-weight: bold;
  text-align: center;
  display: block;
}

.num-driver a,
.num-driver span:nth-child(2) {
  margin-left: 5px;
  text-align: center;
  width: 56px;
  display: ruby;
  color: grey;
}

.num-driver .red,
.num-driver .orange,
.num-driver .green {
  font-weight: bold;
  font-size: 14px;
}
#driver-entries-container > div {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  padding: 3px;
}
.button-green:hover,
.button-red:hover,
.button-orange:hover {
  opacity: 0.8;
}

#buttons-container,
#driver-entries-container {
  margin-bottom: 15px;
  position: relative;
}

#buttons-container button {
  margin-left: 5px;
  margin-right: 5px;
}

#driver-entries-container > p,
#reserve-drivers-container > p {
  margin-bottom: 0px;
  font-weight: bold;
}

#reserve-drivers-container > div {
  border: 1px solid;
  padding: 3px;
  overflow: hidden;
}
#reserve-drivers-container span {
  margin: 0px 3px;
  font-weight: bold;
  font-size: 14px;
}

#race-entries-left .mycanvas {
  width: 700px;
}

.race-laps {
  display: block;
  text-align: center;
}

.hc-drivers b {
  display: block;
  width: 100%;
}
.dates-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#race-entries-left h4 {
  font-weight: bold;
}

.hc-drivers #buttons-container {
  margin-bottom: 5px;
  margin-top: 5px;
  justify-content: center;
}

#registered-drivers {
  background: #179387;
  color: white;
  width: 100%;
  display: block;
  font-weight: bold;
}

.progressBar-liveTiming {
  display: block;
  background-color: red;
  height: 20px;
  left: 0;
  top: 0;
  position: absolute;
}
.progressbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 3px;
}
#driver-entries-container .flex > a {
  width: calc(100% - 90px);
}
.num-driver .driver {
  width: 56px !important;
}

.num-driver .driver span {
  float: left;
}
.dropdown-dates {
  position: absolute;
  top: 32px;
  left: 5px;
  z-index: 1;
  border-radius: 5px;
  background: rgb(43 43 43);
  color: lightgrey;
  border: 1px solid black;
  width: 350px;
}

.dropdown-dates p {
  margin-bottom: 0px;
  padding: 10px 5px;
  border-radius: 5px;
}
.dropdown-dates p:hover {
  cursor: pointer;
}

.dropdown-dates span {
  border-radius: 15px;
  width: 10px;
  padding: 3px 6.5px;
  font-size: 10px;
  margin-right: 5px;
  font-weight: bold;
}

.session-1,
.session-2,
.session-3,
.session-4 {
  border-radius: 15px;
  width: auto !important;
  padding: 3px 6.5px;
  font-size: 10px !important;
  margin-left: 5px;
  font-weight: bold;
  height: 21px;
}

.session-1 {
  background-color: #3a6cb7;
  color: white !important;
}
.session-2 {
  background-color: #e1c819;
  color: black !important;
}
.session-3 {
  background-color: #e337de;
  color: black !important;
}
.session-4 {
  background-color: #9b9a96;
  color: black !important;
}

.dates-container span span {
  margin-right: 5px;
}

.btn-register-slot {
  font-size: 10px !important;
  padding: 1px 4px !important;
}

#race-entries-slot .active {
  background-color: #ffd55f;
}

#race-entries-slot table label {
  font-size: 12px;
}

.driver-slot {
  width: 105px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: -5px;
}
#race-entries-slot {
  width: 70%;
  overflow: auto;
}

.slots #race-entries-left {
  width: 30%;
}
.slots #race-entries-left .mycanvas {
  width: 400px;
}
#race-entries-slot table {
  width: 100%;
  min-width: 770px;
}
#slot-absents,
#slot-unconf {
  margin-top: 20px;
  padding-bottom: 10px;
}
#slot-absents p,
#slot-unconf p {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
#race-entries-slot thead tr {
  text-align: center;
}
#slot-absents button {
  margin-bottom: 7px;
  font-size: 14px;
  padding: 2px 5px;
}

.none {
  width: 100%;
  text-align: center;
  display: block !important;
}
.unconfirmed,
.absents {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se muevan a la siguiente fila cuando se desborde */
  justify-content: center; /* Centra los elementos horizontalmente */
  gap: 5px; /* Espacio entre los labels (opcional) */
}
.unconfirmed label,
.absents label {
  padding: 2px 5px;
  display: flex;
  float: left;
  font-size: 13px;
  margin: 0px 3px;
}

.timezone {
  margin-bottom: 5px;
  font-style: italic;
}

#race-entries-slot img {
  height: 15px;
  max-width: 15px;
  margin-right: 2px;
}

#race-entries-slot td {
  max-width: 100px;
}
@media (max-width: 1180px) {
  #race-entries-container > div {
    display: block;
    text-align: center;
  }
  .slots {
    display: flex !important;
  }
  #race-entries-left,
  #race-entries-left .mycanvas {
    width: 100%;
    max-width: 100%;
  }
  #race-entries-right {
    max-width: 100%;
    display: inline-block;
  }
  #weather-container {
    margin-bottom: 25px;
  }
}

@media (min-width: 1180px) {
  .higher-racentry .mycanvas {
    height: 500px !important;
  }
}

@media (max-width: 950px) {
  .slots {
    display: block !important;
    text-align: center;
  }

  .slots #race-entries-left {
    width: 100%;
  }

  #race-entries-slot {
    width: 100%;
  }

  #race-entries-slot > div {
    overflow: auto;
  }
}

@media (max-width: 400px) {
  .dates-container {
    display: grid;
  }
}
