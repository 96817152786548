.standings-DSQ {
  background: black !important;
  color: white !important;
}
.standings-WINNER,
.standings-THIRD {
  color: black !important;
}

.standings-table {
  font-size: 14px;
  flex-grow: 1;
  max-width: 0px;
  height: 0px;
  float: left;
}

.standings-table tr {
  line-height: 18px;
}

.standings-pos {
  text-align: center;
  font-weight: bold;
  min-width: 30px;
  padding: 0px 3px;
  white-space: nowrap;
}

.standings-table td:nth-child(2) .teamdriver-results-container {
  padding-right: 5px;
}
.standings-results {
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
}

.standings-table thead,
.standings-table tfoot,
.standings-tab-points {
  text-align: center;
}

.qualy-raise {
  float: left;
  top: 5px;
  height: 11px;
  font-size: 10px;
}

.teamdriver-results-container label {
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamdriver-results-container a {
  font-weight: bold;
  display: contents;
  white-space: nowrap;
  display: initial !important;
}

.full-white-driver:hover {
  opacity: 0.9;
}

.gp-link .flag {
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.legend tr td:first-child {
  width: 60px;
  text-align: center;
}
.legend tr td:nth-child(2) {
  width: 180px;
}
.legend {
  font-size: 12px;
  margin-left: 10px;
  min-width: 215px;
  max-width: 350px;
  width: 100%;
  max-height: 300px;
}

.legend label {
  display: block;
}

.standings-teamdriver {
  overflow: hidden;
}

.rdt_TableCell,
.rdt_TableCol {
  font-size: 14px;
  font-family: "Century Gothic" !important;
}

.rdt_TableRow {
  min-height: 0px !important;
}

.data-standings .rdt_TableCell {
  display: block !important;
}

.rdt_TableCol > div > div {
  white-space: inherit;
}

.rdt_TableCell,
.rdt_TableCol {
  border-left: 1px solid black;
}

.rdt_TableCol {
  border-top: 1px solid black;
}
.rdt_TableRow > div:nth-child(2) {
  padding: 0px;
}

.rdt_TableBody .rdt_TableRow:last-child {
  border-bottom: 1px solid black;
}

.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child {
  border-right: 1px solid black;
}
.rdt_TableHead div {
  font-weight: bold;
}

.rdt_TableCell:not(:nth-child(2)) {
  text-align: center;
}

#live-timing-page .rdt_TableCell:first-child,
#live-timing-page .rdt_TableCol:first-child {
  min-width: 49px;
}

.rdt_TableHeadRow div div {
  text-align: center;
  width: 100%;
}
.scoring-table td:nth-child(n + 2) {
  min-width: 30px;
  text-align: center;
}
.scoring-table th {
  text-align: center;
}
.gp-link {
  padding: 0px 2px;
}

.standings-title {
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 0;
  width: 100%;
  top: 54px;
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.standingsdiv-container > div:nth-child(2) {
  margin-top: 70px !important;
}
.data-standings .rdt_TableBody > div > div:last-child {
  padding: 0px;
  font-weight: bold;
}

.data-standings .rdt_TableBody > div > div:last-child > div > div {
  height: 20px;
}

.standings-pos {
  position: sticky;
  left: 0;
  z-index: 2;
}

.standings-teamdriver {
  position: sticky;
  left: 21px;
  z-index: 2;
}

.no-standing-drivers {
  text-align: center;
  height: 300px;
}

.standings-title svg {
  color: rgb(23, 147, 135) !important;
}

.standings-title svg {
  color: rgb(23, 147, 135) !important;
  margin: 0px 15px;
}

.class-tr-separator {
  color: black;
  text-align: center;
  font-weight: bold;
  height: 35px;
}

.hide-class .rdt_TableHeadRow > div:nth-child(3),
.hide-class .rdt_TableRow > div:nth-child(3) {
  display: none !important;
}

.class-container .class_content {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}

.has-many-classes .standings-team a {
  left: 60px;
}

.has-many-classes .standings-points {
  width: 45px;
}

.has-many-classes .standings-position {
  width: 35px;
}

.has-many-classes .teamdriver-container {
  width: 100%;
}

.has-many-classes .secondarychamp-driver a {
  left: 40px;
}

.has-many-classes .secondarychamp-driver .logo-container {
  position: relative;
  float: left;
}

.has-many-classes .secondarychamp-driver a {
  position: relative;
  float: left;
  left: -6px !important;
}
.has-many-classes .teamdriver-container:not(.secondarychamp-driver) a {
  position: relative;
  margin-left: 11px;
}

.has-many-classes
  .teamdriver-container:not(.secondarychamp-driver)
  a:nth-child(2) {
  margin-left: 0px !important;
}

.has-many-classes .secondarychamp-driver label {
  display: none;
}

.has-many-classes .teamdriver-container:not(.secondarychamp-driver) label {
  position: relative;
  left: 60px;
  margin-left: 8px;
}

.standings-tab-points {
  white-space: nowrap;
}

.full-white-driver::after {
  content: attr(data-full-name); /* Por defecto muestra el nombre completo */
}

/* RESPONSIVE */
@media (min-width: 2000px) {
  .standings-class-container > :nth-child(n + 4) {
    display: none;
  }
}
@media (max-width: 1830px) {
  .standings-class-container > :nth-child(n + 3) {
    display: none;
  }
}

@media (max-width: 1240px) {
  .standings-class-container > :nth-child(n + 2) {
    display: none;
  }
}

@media (max-width: 900px) {
  .standings-class-container > div {
    display: block !important;
  }
  .standings-class-container {
    display: block !important;
  }
}

@media (min-width: 762px) {
  .has-many-classes .standings-team img {
    width: 40px;
  }

  .has-many-classes .logo-container {
    width: 65px;
  }
}

@media (max-width: 762px) {
  .has-many-classes .standings-team a {
    left: 10px;
  }
  .has-many-classes .teamdriver-container:not(.secondarychamp-driver) label {
    left: 10px;
  }
  .full-white-driver::after {
    content: attr(
      data-last-name
    ); /* En dispositivos móviles solo muestra el apellido */
  }
}
