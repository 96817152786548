.menu-icons {
  width: 100%;
  justify-content: center;
}

.menu-icons > div {
  max-width: 70px;
  float: left;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}

.menu-icons img {
  width: 100%;
  margin-top: 10px;
}

.menu-icons span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
  display: inline-block;
}
.menu-icons span:nth-child(3) {
  margin-top: -5px;
}
#stats-data-container {
  margin-top: 10px;
}
#stats-data-container > div {
  width: 50%;
  overflow: hidden;
}

.podium-container {
  position: relative;
  width: 453px;
}

.podium-item {
  position: absolute;
  width: 100px;
  height: 118px;
  border-radius: 10px;
}
#teams-stats .podium-item {
  padding: 15px;
}

.podium-item img {
  max-width: 100%;
  max-height: 100%;
}

#drivers-stats .podium-item img {
  border-radius: 10px;
}

.podium-1 {
  left: 175px;
  top: 4px;
}
.podium-2 {
  left: 25px;
  top: 53px;
}
.podium-3 {
  right: 30px;
  top: 81px;
}

.borderRight {
  border-right: 4px solid;
}

.table-stats-container {
  padding: 10px;
}

.prev-values {
  margin-bottom: 15px;
}

#stats-container .rdt_TableCell:nth-child(2) {
  justify-content: left;
  padding: 2px;
}

.photo-top5 {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 8px;
  padding: 5px;
  text-transform: uppercase;
  float: left;
}
.photo-top5 img.driver-img {
  width: 140%;
  margin-left: -10%;
  margin-top: 50%;
}
.photo-top5 img.team-img {
  max-width: 100%;
  max-height: 100%;
}

.top5-container > div {
  display: flex;
  font-size: 19px;
  padding: 0px 10px;
  margin-bottom: 3px;
  font-weight: bold;
}

.top5-container a {
  text-transform: uppercase;
  align-items: center;
}
.top5-container a span {
  margin-top: 4px;
  margin-left: 5px;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.top5-rows span:first-child {
  font-weight: bold;
  margin-right: 10px;
  margin-top: 10px;
}
.top5-rows > span:last-child {
  font-weight: bold;
  width: 100px;
  text-align: center;
  margin-top: 10px;
}

.top5-container {
  width: 442px;
  border: 2px solid;
}

.top5-title span:first-child {
  width: 325px;
  text-align: center;
}
.top5-title span:last-child {
  width: 95px;
  text-align: center;
}

#stats-data-container h3 {
  margin: 0px 15%;
  font-weight: bold;
  margin-bottom: 5px;
}

.podium-container {
  width: 453px;
  height: 250px;
}

.top5-title span:last-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.table-stats-container {
  overflow: auto;
}

.table-stats-container .rdt_TableHeadRow img {
  max-height: 30px;
  max-width: 30px;
}

.table-stats-container .rdt_TableHeadRow,
.table-stats-container .rdt_Pagination {
  min-height: 46px;
}

.hover14.selected {
  font-weight: bold;
  font-size: 14px;
}

.podium-stats img {
  height: 21px;
}

.podium-stats span {
  width: 28px;
  display: inline-grid;
}

#stats-data-container .rdt_TableHeadRow > div {
  font-size: 12px;
}

.present {
  color: #bd1abd;
  font-weight: bold;
}

@media (max-width: 1220px) {
  #stats-data-container {
    display: block;
  }
  #stats-data-container > div {
    width: 100% !important;
  }
  #drivers-stats {
    border: 0px;
  }
  #stats-data-container .button-container {
    display: none;
  }
}

@media (max-width: 1027px) {
  .menu-icons {
    display: inline-block;
  }
  .menu-icons .hover14 {
    height: 106px;
  }
}

/* Shine */

figure {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
figure:hover + span {
  bottom: -36px;
  opacity: 1;
}
.hover14:hover {
  opacity: 1;
  transition: 0.5s;
}

.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
/*
#drivers-stats,
#teams-stats {
  min-width: 465px;
}*/

@media (max-width: 465px) {
  .podium-container {
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 0;
    padding-top: 66.64%;
  }
  .podium-item {
    width: 85px;
    height: 100px;
  }
  .podium-2 {
    left: calc(17% - 45px);
    bottom: 28%;
    top: auto;
  }
  .podium-1 {
    left: calc(50% - 45px);
    bottom: 45%;
    top: auto;
  }
  .podium-3 {
    right: calc(17% - 45px);
    bottom: 19%;
    top: auto;
  }
}
