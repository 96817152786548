#claims > div {
  width: 70%;
  min-width: 1200px;
}

#claims > div h3,
#claim-form,
#claims-container {
  padding: 15px;
}

.deathline svg,
#penalties-container svg {
  font-size: 12px;
  margin-right: 5px;
}
.deathline label {
  margin-right: 5px;
  font-weight: bold;
}

#claim-form {
  margin-right: 15px;
}
#claims-container {
  margin-left: 15px;
}

#claim-form,
#claims-container {
  width: calc(50% - 30px);
}

.license-penalty-container {
  white-space: normal !important;
}
.update-claim {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.update-claim button {
  padding: 2px 15px;
  font-size: 14px;
}
.update-claim.hover {
  opacity: 1;
}

.claim {
  position: relative;
}

#claim-penalty-container {
  width: 100%;
  text-align: center;
}

.claim-penalty {
  width: 100%;
  max-width: 600px;
  margin-bottom: 15px;
}

.claim-penalty > b {
  width: 100%;
  display: block;
}
.claim-penalty > p {
  margin: 10px 0px;
}

.dirver-license-link {
  margin-bottom: 15px;
}

.license-penalty-container > span {
  margin: 1px;
  padding: 2px 5px;
  font-size: 12px;
  display: inline-block;
}
.license-penalty-container > span a {
  text-decoration: none !important;
}

#drivers-licenses {
  width: 100%;
  max-width: 1400px;
}

#claims-container > p {
  margin-top: 25px;
}

.claim {
  border-bottom: 2px solid #179387;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
