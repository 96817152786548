.day-selected {
  background: #3bd8c9 !important;
}
.day-selected-sec {
  background: #e37642 !important;
}
.day-selected-eve {
  background: #ffff13 !important;
}
.day-selected-sec abbr,
.day-selected-eve abbr {
  color: black !important;
}
.day-selected abbr {
  color: black !important;
}
.react-calendar {
  max-width: 420px !important;
  width: auto !important;
  font-family: "Century Gothic" !important;
  min-height: 384px;
}

.react-calendar__navigation,
.react-calendar__navigation > button:focus {
  background: #3bd8c9 !important;
}

.react-calendar__navigation > button:hover {
  background: #5af8e8 !important;
}

.react-calendar__tile.day-selected-sec:hover {
  background: #ffad86 !important;
}

.react-calendar__tile.day-selected-eve:hover {
  background: #ffff47 !important;
}
.react-calendar__tile:hover {
  background: #affff7 !important;
}

.react-calendar__tile--active {
  background: #affff7 !important;
  color: black;
}

.react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.titleContainer {
  width: 181px;
  background: #202020;
  height: 25px;
  z-index: 2147483647;
  opacity: 0;
  position: absolute;
  border-radius: 3px;
  border: 1px solid #3b3b3b;
  transition: 0.5s opacity;
}

.titleContainer span {
  color: rgb(201, 201, 201);
}

.react-calendar__tile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: inherit !important;
}

.react-calendar__navigation__label {
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.react-calendar {
  border: 0px;
}

.react-calendar__navigation {
  margin-bottom: 0px;
  height: 80px;
}
.react-calendar__viewContainer {
  font-size: 13px;
  padding: 30px;
}

.react-calendar__navigation button {
  padding: 24px;
}

.react-calendar__viewContainer button:hover abbr {
  color: #1b1b1b;
}

.react-calendar__tile abbr:hover {
  color: black !important;
}

.react-calendar__tile--now {
  background: none;
  font-weight: bold;
}

.react-calendar__tile--now::before {
  content: "";
  position: absolute;
  top: 0px; /* Ajusta según el grosor del borde */
  left: 0px; /* Ajusta según el grosor del borde */
  right: 0px; /* Ajusta según el grosor del borde */
  bottom: 0px; /* Ajusta según el grosor del borde */
  pointer-events: none; /* Asegura que el pseudo-elemento no interfiera con eventos de puntero */
  box-sizing: border-box; /* Asegura que el borde se incluya en las dimensiones */
}

#calendar-parent {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en filas */
  justify-content: flex-start;
}

#calendar-parent > div {
  width: calc(20% - 4px); /* Cada div ocupa el 20% del ancho */
  box-sizing: border-box; /* Asegura que padding y border no afecten el ancho total */
  padding: 10px; /* Espaciado interno opcional */
  border: 1px solid #ccc; /* Bordes opcionales */
  border-radius: 5px;
  text-align: center;
  height: 380px;
}
#calendar-parent > div .mycanvas {
  height: 200px;
}
.calendar-race {
  position: relative;
  margin: 2px;
}
.calendar-race > label {
  display: inherit;
}
.date-calendar {
  font-size: 14px;
}
.laps-calendar {
  font-size: 11px;
}
.circuit-calendar {
  font-style: italic;
}
.winners-calendar img {
  max-width: 15px;
  height: 10px;
  margin-top: -8px;
}
.winners-calendar span {
  font-size: 12px;
  margin-top: -6px;
}
.winners-calendar {
  justify-content: space-around;
}
.round-calendar {
  position: absolute;
  font-weight: bold;
  font-size: 18px;
  width: 35px;
  height: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: calc(50% - 12.5px);
  left: 0;
  border-radius: 0px 5px 5px 0px;
}

.ellipsis-label span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 99px; /* Aplica el ancho límite solo al texto */
  display: inline-block; /* Necesario para que funcione text-overflow */
}
.trophy-calendar {
  color: #f7bc30;
  font-size: 13px;
  margin-right: 4px;
}
.winnerC-title {
  font-size: 13px;
  font-weight: bold;
}
.arrowResults {
  color: #ff0000;
  margin-right: 5px;
}

/* MOBILE + STYLES V2 */
@media (max-width: 400px) {
  .react-calendar button,
  .react-calendar__viewContainer {
    padding: 8px;
  }
  #calendar-div > div {
    height: 245px;
  }
  .react-calendar {
    height: 288px !important;
    min-height: 0;
  }
  #calendar-div {
    height: 288px !important;
    margin-bottom: 30px;
    margin-top: -20px;
  }
}

@media (max-width: 1840px) {
  .calendar-race {
    width: calc(25% - 4px) !important; /* Cada div ocupa el 20% del ancho */
  }
}

@media (max-width: 1260px) {
  .calendar-race {
    width: calc(33.3% - 4px) !important; /* Cada div ocupa el 20% del ancho */
  }
}

@media (max-width: 950px) {
  .calendar-race {
    width: calc(50% - 4px) !important;
  }
}

@media (max-width: 660px) {
  .calendar-race {
    width: calc(100% - 4px) !important;
  }
}
