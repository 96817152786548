.team-car-image {
  max-height: 35px;
}

.team-table {
  font-size: 12px;
  min-width: 600px;
}

.team-delimiter {
  border-bottom: 3px solid;
}
.team-table tbody {
  border: 3px solid;
}
.game-icon {
  max-height: 29px;
  max-width: 35px;
}
.team-logo-results {
  max-height: 100px;
  max-width: 100px;
}
.team-stats-container.text-left {
  padding-left: 10px;
}
.team-stats-container.text-right {
  padding-right: 10px;
}
.team-stats-container {
  padding-top: 10px;
  width: 195px;
}
.team-table tr td:first-child {
  width: 40px;
}
.team-driver-td {
  width: 40px;
}
.team-car-td {
  width: 10px;
}

#team-logo-mobile {
  display: none;
}

@media (max-width: 600px) {
  .team-stats-container {
    display: none;
  }
  #team-logo-pc {
    display: none;
  }
  #team-logo-mobile {
    display: block;
  }
  #team-logo-mobile > div {
    width: 100%;
    text-align: center;
    display: block;
  }
  #team-logo-mobile h3 {
    margin-bottom: 0px;
  }
}
