.submenu-button {
  background: transparent;
  border: 0px;
  padding: 2px;
  margin: auto 5px;
  margin-bottom: 28px;
}

.submenu-button svg {
  margin-right: 2px;
}

#gp-title {
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 10px;
}

.submenu-selected {
  border-bottom: 2px solid #3bd8c9;
}

#index-gp-container {
  height: calc(100vh - 216px);
}

.video-container,
.image-container {
  height: calc(50vh - 123px);
  margin-bottom: 10px;
  padding-top: 12px !important;
}

.video-container iframe,
.image-container img {
  height: calc(50vh - 167px);
}

#press-conference {
  width: 100%;
  height: 200px;
  bottom: 0;
}

#index-gp-container .frame-turqoise {
  padding: 5px;
}

.jp-audio {
  background-color: red !important;
}

#gp-data {
  height: calc(100vh - 224px);
}
#gp-data-withconf {
  height: calc(100vh - 416px);
}

#index-gp-container .main-title {
  font-size: 3vh;
  line-height: 1.2;
  margin-bottom: 7px;
}

#gp-results-layout-container,
#milestones-container {
  height: calc(50vh - 128px);
}

.milestones-data {
  height: calc(50vh - 162px);
  overflow: auto;
  padding-top: 5px;
}

#gp-results-layout-container .mycanvas {
  height: calc(50vh - 162px);
}

#gp-results-layout-containerx2 {
  height: calc(100vh - 224px);
}
#gp-results-layout-containerx2 .mycanvas {
  height: calc(100vh - 267px);
}

#gp-results-layout-containerx2 .button-red {
  display: none;
}

#gp-overview {
  /*height: calc(100vh - 226px);*/
  margin-right: 28px;
  position: relative;
}

.milestones-data p {
  font-size: 14px;
}

#summary-data-content {
  padding-top: 10px;
}

#summary-data-content svg {
  margin-right: 3px;
  margin-top: 3px;
}

#summary-data-content b {
  margin-right: 12px;
}

.image-container img {
  max-width: 100%;
}

.bar-team {
  width: 15px;
  height: 30px;
}

.chart-container,
.gp-incidents-container {
  margin-bottom: 50px;
}
.penalties-chat-container {
  width: 40%;
}

.chart-container label,
.label-title-gp {
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 22px;
}

.incidents-container {
  width: calc(60% - 30px);
  margin-right: 30px;
  max-width: 1160px;
}

.text-penalties-container {
  max-height: calc(50vh - 180px);
  overflow: auto;
}

.text-penalties-container p {
  margin-bottom: 5px;
  font-size: 14px;
}

.chat-penalty-container {
  margin-bottom: 30px;
}
.text-penalties-container table {
  width: 100%;
  font-size: 14px;
}

.driver-of-the-day-container {
  width: 40%;
}
.statements-container {
  width: calc(60% - 30px);
  margin-left: 30px;
}
#voting-driver-of-the-day {
  text-align: center;
}
#voting-drivers-container {
  margin-top: 30px;
  margin-bottom: 30px;
  max-height: calc(100vh - 385px);
  overflow: auto;
  text-align: left;
  margin-right: 30px;
}

.post-race-button {
  border: 0px;
  padding: 5px 10px;
  box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
  border-radius: 3px;
}

.write-statement-container {
  height: 100px;
  width: 100%;
}

.write-statement-container textarea {
  width: 100%;
  margin-right: 20px;
  border-color: #dddddd;
  border-radius: 10px;
  padding: 10px 15px;
}
.write-statement-container textarea:focus {
  outline: none !important;
}

.write-statement-container button {
  background: rgb(221, 221, 221);
  color: rgb(0, 0, 0);
  height: 37px;
  margin-top: 29px;
}
#no-statements-message,
#no-votes-message {
  margin: 20px 0px;
  text-align: center;
}

.submenu-button label {
  cursor: pointer;
}

#last-gp-layout-container {
  position: relative;
}

#claim-form .dateFormat {
  font-size: 12px;
}
#claim-form .small-text {
  margin-bottom: 0px;
}
.chart-container .frame-title {
  display: none;
}
#summary-data-content .class_content {
  margin: 0px 3px -3px 3px;
}
.line-results {
  display: inline-block;
  width: 100%;
}

.line-results .driver {
  display: flex;
}

.line-results .driver img {
  margin-top: 4px;
}

.line-results > * {
  float: left !important;
}
.clickable-class {
  cursor: pointer;
}
.clickable-class:hover {
  filter: brightness(1.3); /* Aumenta el brillo en un 30% */
  transition: background-color 0.3s ease-in-out; /* Transición suave */
}
.brand-image {
  width: 15px !important;
  margin-right: 3px;
}
@media (max-width: 1260px) {
  #index-gp-container,
  .gp-incidents-container {
    display: inline;
  }
  #index-gp-container .col-md-8 > div > div:first-child,
  .video-container {
    width: calc(50% - 15px);
  }
  #index-gp-container .col-md-8 > div > div:first-child,
  #index-gp-container .col-md-4 > div:first-child {
    margin-right: 15px;
  }
  #index-gp-container .col-md-8 > div > div:last-child,
  #index-gp-container .col-md-4 > div:last-child {
    margin-left: 15px;
  }
  #index-gp-container,
  #gp-data-withconf,
  .video-container,
  .image-container,
  #gp-data,
  #gp-overview,
  .session,
  .text-penalties-container {
    height: auto;
    max-height: initial;
  }
  .video-container,
  .image-container,
  .penalties-chat-container {
    padding: 0px 5px;
  }
  #index-gp-container > div,
  .incidents-container,
  .penalties-chat-container {
    width: 100%;
  }
  #index-gp-container > div:nth-child(2) {
    display: flex;
  }
  #index-gp-container > div:nth-child(2) > div {
    width: 50%;
  }
  .video-container iframe {
    height: 300px;
  }
  #gp-overview {
    margin-right: 0px;
  }
  .gp-incidents-container {
    margin: 15px;
  }
}

@media (max-width: 900px) {
  #gp-title {
    font-size: 28px;
    margin-top: 15px;
  }
  #gp-data > div,
  .driver-of-the-day-container,
  .statements-container {
    width: 100%;
  }
  .statements-container {
    width: 100%;
    margin-left: 0px;
  }
  .incidents-container {
    width: 100%;
    margin-right: 0px;
  }

  #gp-data,
  #gp-data-withconf,
  .post-race-container {
    display: block;
  }
  #index-gp-container > div:nth-child(2) {
    display: block;
  }
  #index-gp-container > div:nth-child(2) > div {
    width: 100%;
  }
  .submenu {
    justify-content: initial !important;
    overflow: auto;
    margin: 10px;
    margin-bottom: 20px;
  }
  .submenu-button {
    margin-bottom: 5px;
  }
  .submenu-button label {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .chart-container label,
  .driver-of-the-day-container > label,
  .statements-container > label {
    font-size: 16px;
  }
  .chart-container > div {
    height: 500px !important;
  }
  #voting-drivers-container {
    display: block;
    width: 100%;
    padding: 0px 15px;
  }
  #voting-driver-of-the-day {
    margin-bottom: 30px;
  }
  #findings-div {
    display: block;
  }
  #claim-form,
  #claims-container {
    width: 100% !important;
    margin: 0px 0px 25px 0px !important;
  }
  #index-gp-container .col-md-8 > div > div:first-child {
    width: 100%;
  }
  #index-gp-container .col-md-8 > div > div:last-child,
  #index-gp-container .col-md-4 > div:last-child {
    margin-left: 0px;
  }
}
@media (height < 787px) {
  #press-conference {
    display: none;
  }
}

@media (max-width: 762px) {
  #gp-results-layout-container {
    height: auto;
  }
}

@media (max-width: 1428px) {
  .rq-session {
    margin-top: 5px;
    justify-content: initial !important;
    padding: 5px;
    overflow: auto;
    height: auto;
    min-height: 0 !important;
  }
}

@media (max-width: 1080px) {
  .fl-session {
    margin-top: 5px;
    justify-content: initial !important;
    padding: 5px;
    overflow: auto;
    height: auto;
    min-height: 0 !important;
  }
}

@media (max-width: 680px) {
  .ld-session {
    margin-top: 5px;
    justify-content: initial !important;
    padding: 5px;
    overflow: auto;
    height: auto;
    min-height: 0 !important;
  }
}
