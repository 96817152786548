.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Document {
  overflow: hidden;
  height: calc(90vh - 55px) !important;
  width: auto !important;
  max-width: 100%;
  position: relative;
}

.react-pdf__message {
  display: none;
}

.tools {
  position: absolute;
  width: 50px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 3px;
  right: 3px;
}

.tools > span {
  height: 50px;
  width: 50px;
  text-align: center;
  color: white;
  display: block;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding-top: 7px;
  transition: 0.3s;
}
.tools > span:hover {
  background-color: hsl(174deg 75% 45%);
}

#prev-page {
  left: -25px;
}
#next-page {
  right: -25px;
  color: red;
}

#prev-page,
#next-page {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding-top: 6px;
  cursor: pointer;
}

#regulation {
  position: relative;
  /*max-width: 100%;*/
  min-width: 335px;
  width: 100%;
}

.react-pdf__Page {
  overflow-x: auto;
  overflow-y: hidden;
}

.zoomed .react-pdf__Page {
  overflow: initial;
}

.zoomed :hover {
  cursor: grab;
}

.zoomed :active {
  cursor: grabbing;
}

.fold {
  box-shadow: -1px 1px 19px 6px #ccc;
  height: 100%;
  width: 0px;
  position: absolute;
  top: 0;
  left: 2px;
}

@media (max-width: 680px) {
  #prev-page {
    left: 0px;
  }
  #next-page {
    right: 0px;
  }
}
