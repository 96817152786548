#transfers-container {
  width: 100%;
}
#picker-list {
  width: 30%;
}
#teams-list {
  height: auto;
}
.team-transfer img {
  width: 100%;
}
.team-transfer {
  height: 150px;
}
#teams-list a.driver {
  width: 100%;
  text-align: left;
}
#teams-list .num-driver {
  display: block;
  clear: both;
}

#teams-list .num-driver span {
  float: left;
  margin-right: 5px;
}

.arrows-gif {
  width: 20px !important;
  cursor: pointer;
}

#picker-list > div {
  max-height: calc(100vh - 175px);
  overflow: auto;
  margin-right: 15px;
}

#picker-list > div p {
  margin-bottom: 5px;
  display: table;
  padding-right: 6px;
}

#picker-list > div p span {
  padding: 0px 6px;
  width: 30px;
  display: block;
  text-align: center;
  float: left;
  margin-right: 5px;
}

.transfers1 {
  text-decoration: line-through;
  opacity: 0.6;
}

.transfers2 {
  text-decoration: line-through;
  opacity: 0.6;
  color: red;
}
.sign-button {
  padding: 4px 8px;
  font-size: 12px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  #teams-list {
    display: inline-table;
  }
}
