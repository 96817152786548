.navbar {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  display: inline-table !important;
  padding: 0px !important;
}
.container-fluid,
.navbar-brand {
  min-height: 63px;
}

.navbar {
  padding: 17px;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  right: 0;
  left: 0;
}

.dropdown-menu {
  padding: 0px 5% !important;
}

.navbar-brand {
  font-family: "Formula1Bold", serif;
  font-size: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-fixed {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 15 !important;
}

.navbar-fixed #lvf1-title {
  color: #e0e0e0;
}

.navbar-fixed a {
  color: #e0e0e0 !important;
}

.navbar-nav > a {
  display: flex;
  align-items: center;
}

.navbar-nav > div,
.navbar-nav > a {
  font-size: 18px;
  margin: 10px 15px;
}

.navbar-nav > div:hover > a,
.navbar-nav a:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 6px;
  text-decoration-color: rgb(23, 147, 135) !important;
}

.active-menu > a,
a.active-menu {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 6px;
}
.dropdown-menu-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  transform-origin: top;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-menu-horizontal.show {
  opacity: 1;
  transform: translateY(0);
}
.fade-effect {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.fade-effect.show {
  opacity: 1;
}

.dropdown-menu a:active,
.dropdown-menu a:focus {
  background: transparent !important;
}

.dropdown-menu a {
  font-size: 15px;
}
.navbar-brand {
  margin-left: 10%;
}
.navbar-collapse {
  margin-right: 10%;
}
.link-login {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

@media (min-width: 992px) {
  .dropdown {
    position: initial !important;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-menu {
    display: flex !important;
    position: absolute !important;
    left: 0px !important;
    top: 46px !important;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  }
  .dropdown-menu a {
    padding: 37px 10px !important;
    text-align: center;
  }

  .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

/* MOBILE STYLES */
@media (max-width: 991px) {
  .navbar-nav-scroll {
    display: contents !important;
  }
  .navbar-nav.d-flex {
    display: block !important;
  }
  .navbar a {
    color: #e5e5e5 !important;
  }
}
