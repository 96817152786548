@font-face {
  font-family: "Formula1";
  src: url("../fonts/formula.otf");
}
@font-face {
  font-family: "Formula1Bold";
  src: url("../fonts/formula-bold.otf");
}
@font-face {
  font-family: "Century Gothic";
  src: url("../fonts/main.ttf");
}
@font-face {
  font-family: "Plateusa";
  src: url("../fonts/plateusa.ttf");
}
