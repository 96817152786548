.nextGP {
  width: 100%;
  height: 143px;
}
.nextGP-empty {
  width: 100%;
  height: 10px;
}

.nextGP > div {
  padding: 9px 10%;
}

#next-gp-container path {
  stroke-width: 2px;
}

.fullHeight {
  height: calc(100vh - 120px) !important;
}

#next-gp-container .mycanvas-container,
#next-gp-container .mycanvas,
#last-gp-layout-container,
#next-gp-container {
  height: 120px !important;
}

#next-gp-container,
#last-gp-layout-container {
  display: inline-block;
  width: 120px;
}

#track-container {
  display: grid;
  color: white;
  width: 120px;
}

#circuit-info-container {
  display: flex;
}

#name-countdown-container {
  width: calc(100% - 520px);
  margin-top: 15px;
  color: white;
  margin-left: 22px;
  position: relative;
}
#name-countdown-container span {
  height: 30px;
  font-size: 12px;
}
#name-countdown-container h4 {
  font-size: 28px;
  font-weight: bold;
}

.countdown-days {
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-radius: 5px;
}
#next-gp-container canvas {
  position: absolute;
  top: 0;
}

.small-text {
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.small-text > div {
  margin-left: 10px;
  position: relative;
}

.icon-container {
  position: absolute;
  top: 9px;
  left: 3.5px;
  display: inline-block;
  padding: 0;
}

.icon-container::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  background: red;
  opacity: 0;
  animation: pulse 2s infinite;
}

.small-text svg {
  color: red;
  font-size: 8px;
  top: 5px;
  position: absolute;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.carousel-root {
  position: relative;
  height: 125px;
  width: 400px;
  background: #e9f4ff;
  border-radius: 5px;
}

.dateFormat-separator {
  margin: 0px 5px;
}

.buttonDivisorRight {
  position: absolute;
  right: 0;
  top: 0;
  height: 125px;
  background: #3bd8c9;
  z-index: 1;
  width: 50px;
  border-radius: 0px 5px 5px 0px;
}

.buttonDivisorRight span {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  position: absolute;
  bottom: 52px;
  left: -38px;
  white-space: nowrap;
  font-size: 13px;
  width: 123px;
}

.carousel .control-dots .dot {
  background: #2b2b2b !important;
}

.carousel-slider {
  height: 125px;
  color: black;
}
.slider-wrapper {
  height: 400px;
}

.control-dots {
  margin: 4px 0px !important;
}

.slide > div > div {
  width: 350px;
}

.slider-1 label {
  font-weight: bold;
}

.slider-1 small {
  font-size: 11px;
}

.slider-1 > div {
  margin-top: 5px;
}
.btn-hover {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #3bd8c9,
    #43f7e5,
    rgb(90, 233, 219),
    #45ecdc
  );
}
.slider-2 {
  height: 95px;
}
.slider-2 > div:first-child {
  width: 30%;
  display: grid;
  padding: 5px;
  font-size: 14px;
}

.medium-border-left {
  height: 82px;
  width: 2px;
  background: #2b2b2b;
  margin-top: 16px;
  position: absolute;
}

.slider-2 > div:first-child span:first-child {
  font-weight: bold;
  margin-top: 22px;
}

.slider-2 > div:last-child {
  width: 70%;
}

.weather {
  font-size: 12px;
  margin-left: 55px;
}

.weather svg {
  margin-left: 5px;
}

.live-container {
  margin-left: 60px;
  margin-top: 8px;
}

.driver-live-container {
  font-size: 12px;
  margin-bottom: 6px;
  display: flex;
}

.driver-live-container .pos {
  width: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 2px;
  margin-right: 3px;
}

.driver-live-container .driver-name {
  width: calc(100% - 70px);
  text-align: left;
  padding-left: 3px;
}

.driver-live-container .driver-time {
  width: 70px;
  background: #2b2b2b;
  border-radius: 10px;
  color: #e9e9e9;
}

.div-padd {
  padding: 3px 8px;
}

.driver-live-container .bar-color {
  width: 3px;
  height: 15px;
  margin-top: 2px;
}

.div-padd small {
  margin-top: 14px;
  display: block;
  font-size: 13px;
}

#title-fastestlaps {
  font-weight: bold;
  font-size: 14px;
}

.slider-3 .team-logo {
  width: 20px;
  max-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.slider-3 .team-logo img {
  max-height: 15px;
  max-width: 15px;
  width: auto !important;
}

.div-hl-driver {
  width: 213px;
  display: flex;
}

.slider-3 .driver-name {
  width: 150px;
}

.slider-3 .div-padd {
  padding: 3px 20px;
}

#news-container {
  min-height: calc(100vh - 232px);
  margin-top: 20px;
  margin-bottom: 40px;
}

#indexfooter-container {
  margin-bottom: 30px;
}

#indexfooter-container > div {
  margin: 0px 10%;
  position: relative;
}

.nofastestlaps {
  margin-top: 28px !important;
}
#last-gp-container {
  width: 40%;
  margin-left: 10%;
  padding-right: 15px;
}

#last-gp-container img {
  width: 100%;
  transition: transform 0.3s, filter 0.3s; /* Transición suave */
}
#individual-news-container {
  width: 60%;
  padding-right: 10%;
}

#last-gp-container h4 {
  font-weight: bold;
  text-align: center;
}
#last-gp-container:hover {
  cursor: pointer;
}

#last-gp-container span {
  margin-top: 5px;
  display: block;
  text-align: justify;
}

.secondarychamp-driver .logo-container {
  width: 41px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 0px;
}

.secondarychamp-driver a {
  left: 40px;
}

.secondaryChamp {
  height: 290px;
}

#last-gp-container > div a {
  text-decoration: none;
}

.noticia .imagen {
  transition: opacity 0.3s ease-in-out;
}

.noticia .imagen:hover {
  opacity: 0.8;
}

#individual-news-container .noticia {
  width: 25%;
}

#individual-news-container .noticia img {
  width: 100%;
}

.news-item div {
  margin-bottom: 15px;
  width: 100%;
}

.news-container {
  display: flex;
  flex-wrap: wrap;
}

.news-item {
  height: calc(50vh - 146px);
  border-bottom-right-radius: 10px;
  padding: 5px;
}

.news-container > div:nth-child(2n) {
  margin-left: 20px;
  width: calc(50% - 20px);
  margin-bottom: 30px;
}
.news-container > div:nth-child(2n + 1) {
  margin-right: 20px;
  margin-left: 10px;
  width: calc(50% - 30px);
  margin-bottom: 25px;
}

#individual-news-container ul {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 90%;
  grid-gap: 1em;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 2em 1em;
}
.imagen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra la imagen */
  width: 100%; /* Ancho del 100% del divisor */
  height: 100%; /* Alto del 100% del divisor */
  display: flex; /* Utiliza el modelo de caja flexible */
  align-items: center; /* Centra verticalmente la imagen */
  justify-content: center; /* Centra horizontalmente la imagen */
  overflow: hidden; /* Oculta cualquier contenido que se salga del contenedor */
}
.carousel .control-dots {
  z-index: 0 !important;
}
.imagen img {
  width: 115%; /* Ancho automático para mantener el aspecto original */
  height: auto; /* Alto automático para mantener el aspecto original */
  display: block;
  transition: transform 0.3s, filter 0.3s; /* Transición suave */
  object-fit: cover;
}
.news-item:hover {
  cursor: pointer;
}
.news-item:hover .imagen img {
  filter: brightness(1.2); /* Aplica el efecto de aclarado */
}

#last-gp-container:hover img {
  filter: brightness(1.2); /* Aplica el efecto de aclarado */
}
.news-item > div:first-child {
  position: relative;
  height: calc(100% - 80px);
}

.contenedor-h3 {
  width: 100%;
  height: 55px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.news-item h3,
#name-countdown-container h4 {
  width: 100%;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Máximo de líneas permitidas antes de aplicar los puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.news-item h3 {
  text-align: center;
  font-size: 20px;
}

#last-gp-container > div {
  padding-left: 0px;
}
/* MED SCREEN STYLES */
@media (max-width: 1470px) {
  .nextGP > div {
    padding: 9px 30px;
  }
  #individual-news-container {
    padding-right: 30px;
  }
  #last-gp-container {
    margin-left: 30px;
  }
  #indexfooter-container > div {
    margin: 0px 30px;
  }
  .navbar-brand,
  #switch-container {
    margin-left: 10px;
  }
  .navbar-collapse {
    margin-right: 0px;
  }
}

/* TABLET STYLES */
@media (max-width: 1070px) {
  .fullHeight {
    height: auto !important;
  }
  .nextGP > div {
    padding: 9px 15px;
  }
  #individual-news-container {
    padding-right: 15px;
  }
  #last-gp-container {
    margin-left: 15px;
  }
  #indexfooter-container > div {
    margin: 0px 150px;
  }
  #news-container {
    display: block;
    height: auto;
  }
  #last-gp-container {
    width: calc(100% - 30px);
  }
  #individual-news-container {
    width: calc(100% - 45px);
    margin-top: 30px;
    padding-right: 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #indexfooter-container > div {
    margin: 0px 15px;
  }
  .container-fluid {
    padding: 0;
  }
  .navbar-toggler {
    margin-right: 10px;
  }
  .dropdown-menu {
    padding: 0px !important;
  }
  #individual-news-container {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }
  .news-container .news-item {
    padding-left: 0px;
    margin-left: 0px !important;
    /*width: calc(50% - 15px) !important;*/
  }
}

/* MOBILE + STYLES */
@media (max-width: 900px) {
  #indexfooter-container {
    height: auto;
  }
  #indexfooter-container > div {
    display: block;
  }
  #calendar-div,
  #next-gp-standings {
    padding: 0px;
  }
  #index-container {
    display: block;
  }
  #index-container > div,
  .categoryWrapper {
    width: 100% !important;
    height: 50vh;
  }
  .categoryWrapper button span {
    font-size: 8vw;
  }
  #track-container {
    display: none;
  }
  #name-countdown-container {
    width: 100%;
    text-align: center;
    margin-left: 0px;
    margin-top: 0px;
  }
  #circuit-info-container {
    display: block;
  }
  .nextGP {
    height: auto;
  }
  #homecarousel-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
  }
}

/* MOBILE + STYLES */
@media (max-width: 680px) {
  .news-item {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .news-container {
    display: block;
  }
  #next-gp-standings {
    padding: 0px 10px;
  }
  #indexfooter-container > div h4 {
    font-size: 5vw;
  }
}

/* MOBILE + STYLES V2 */
@media (max-width: 400px) {
  .carousel-root {
    width: 100%;
  }
  .slide > div > div {
    width: calc(100% - 50px);
  }
}
