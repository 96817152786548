.app-container {
  margin-top: 64px;
}

.flex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.right {
  float: right;
}

.strike {
  text-decoration: line-through;
}

.separator {
  margin: 0px 5px;
}

.column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-around {
  justify-content: space-around;
  align-items: center;
}

.padding-md {
  padding: 30px 10%;
}
.block {
  display: block;
}

.flex-container {
  overflow: auto;
  padding-bottom: 7px;
}

.flag {
  height: 15px;
  margin-top: -3px;
  margin-right: 4px;
  max-width: 22px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-container-lg {
  width: 1400px;
}
.form-container-md {
  width: 1200px;
}
.driver,
.white-driver {
  text-decoration: none;
}

.noBottomMargin {
  margin-bottom: 0px;
}

hr {
  margin: 10px !important;
}

.hide {
  display: none;
}

.full-width {
  width: 100%;
}

/* START BUTTON-LVF1 */
.button-lvf1-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-lvf1-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-lvf1-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(174deg 77% 25%) 0%,
    hsl(174deg 100% 32%) 8%,
    hsl(174deg 63% 34%) 92%,
    hsl(174deg 100% 16%) 100%
  );
}

.button-lvf1-front {
  display: block;
  position: relative;
  padding: 6px 16px;
  border-radius: 12px;
  font-size: 0.9rem;
  color: white;
  background: hsl(174deg 75% 45%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.highlight-button {
  font-size: 1.25rem;
  padding: 12px 42px;
}

.button-lvf1-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-lvf1-pushable:hover .button-lvf1-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-lvf1-pushable:active .button-lvf1-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-lvf1-pushable:hover .button-lvf1-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-lvf1-pushable:active .button-lvf1-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-lvf1-pushable:focus:not(:focus-visible) {
  outline: none;
}
/* END BUTTON LVF1 */

.small-text {
  font-size: 13px;
  margin-bottom: 0px;
}

.button-red {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  background: #d50027;
  padding: 5px 10px;
  border-radius: 5px;
}
.button-red:hover {
  color: #ffffff;
}
.blink {
  animation: blink 2s linear infinite;
}

.top-left {
  position: absolute;
  top: 0;
  left: 20px;
}

.full-height {
  min-height: calc(100vh - 264px);
}

.height-100 {
  min-height: 100vh;
}

.padding-lg {
  padding: 30px;
}

.frame-turqoise {
  border-top: 8px solid #179387;
  border-right: 8px solid #179387;
  padding: 15px;
  border-top-right-radius: 15px;
  position: relative;
}

.frame-turqoise .frame-title {
  position: absolute;
  top: -23px;
  left: 30px;
  padding-right: 21px;
  padding-left: 21px;
  font-weight: bold;
  font-size: 26px;
}

.padding-xl {
  padding: 15px 5%;
}

.main-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.secondary-title {
  text-align: center;
  font-size: 2.5vh;
  font-weight: bold;
}

.flex-center-top {
  display: flex;
  justify-content: center;
}

.low-opacity {
  opacity: 0.1;
}

.med-opacity {
  opacity: 0.6;
}

.overflowed {
  overflow: auto;
}

@keyframes blink {
  0% {
    background: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.form-group {
  margin-bottom: 15px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
}

.form-select.is-invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
}
.label-icon {
  margin-left: 5px;
}

.relative {
  position: relative;
}

.grey {
  color: rgb(160, 150, 150);
}

.square-bubble {
  position: absolute;
  height: 30px;
  width: auto;
  background: rgb(37, 37, 37);
  color: white;
  white-space: nowrap;
  top: -10px;
  padding: 5px;
  left: -65px;
}
.square-bubble:before {
  border: 8px solid rgb(37, 37, 37);
  content: "";
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  bottom: -16px;
  left: calc(50% - 7px);
}

/* TOOLTIP */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}
[data-tooltip]:before,
[data-tooltip]:after {
  line-height: 2;
  font-size: 0.9em;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  display: none;
  opacity: 0;
}
[data-tooltip]:before {
  content: "";
  border: 5px solid transparent;
  z-index: 100;
}
[data-tooltip]:after {
  content: attr(data-tooltip);
  text-align: center;
  border-radius: 3px;
  z-index: 99;
  padding: 6px 8px;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  display: block;
  opacity: 1;
}
[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
  bottom: 100%;
  border-bottom-width: 0;
}
[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
  bottom: calc(100% + 5px);
}
[data-tooltip]:not([data-flow])::before,
[tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, -4px);
  transform: translate(-50%, -4px);
}
[data-tooltip][data-flow="bottom"]::before {
  top: 100%;
  border-top-width: 0;
}
[data-tooltip][data-flow="bottom"]::after {
  top: calc(100% + 5px);
}
[data-tooltip][data-flow="bottom"]::before,
[data-tooltip][data-flow="bottom"]::after {
  left: 50%;
  -webkit-transform: translate(-50%, 8px);
  transform: translate(-50%, 8px);
}
[data-tooltip][data-flow="left"]::before {
  top: 50%;
  border-right-width: 0;
  left: calc(0em - 5px);
  -webkit-transform: translate(-8px, -50%);
  transform: translate(-8px, -50%);
}
[data-tooltip][data-flow="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  -webkit-transform: translate(-8px, -50%);
  transform: translate(-8px, -50%);
}
[data-tooltip][data-flow="right"]::before {
  top: 50%;
  border-left-width: 0;
  right: calc(0em - 5px);
  -webkit-transform: translate(8px, -50%);
  transform: translate(8px, -50%);
}
[data-tooltip][data-flow="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  -webkit-transform: translate(8px, -50%);
  transform: translate(8px, -50%);
}
[data-tooltip=""]::after,
[data-tooltip=""]::before {
  display: none !important;
}
[data-tooltip]:after {
  width: 350px !important;
}

.low-bottom-margin {
  margin-bottom: 5px;
}

.top-margin {
  margin-top: 0px !important;
}

#error-container,
#maintenance-container {
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 110px;
  text-align: center;
}
#error-container h3,
#maintenance-container h3 {
  font-size: 54px;
  font-weight: bold;
}
#error-container p,
#maintenance-container p {
  font-size: 28px;
  margin-top: 20px;
}

.modal {
  color: black !important;
}

.show-block {
  display: block !important;
}

.fa-spinner {
  animation: spin infinite 2s linear;
  font-size: 10vh;
}

.page-transition {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

table td {
  padding: 1px 3px;
  overflow-wrap: anywhere;
}

/* Customize the label (the container) */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  width: 200px;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: hsl(174deg 75% 45%);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.no-td-padding {
  padding: 0px !important;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.grid {
  display: grid;
}

.notification {
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
}
.dropdown-toggle .notification {
  margin-right: 0px;
}
.align-top {
  align-items: start !important;
}

.frame-turqoise {
  margin-bottom: 25px !important;
}
.frame-sm {
  border-top: 4px solid #179387;
  border-right: 4px solid #179387;
}
.nopointer:hover {
  cursor: auto !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 600px !important;
  }
}

@media (max-width: 900px) {
  .frame-turqoise .frame-title {
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 31.19px;
    font-size: 22px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 3px;
  }
  .padding-md {
    padding: 15px !important;
  }
}

/* MED SCREEN STYLES */
@media (max-width: 1470px) {
  .padding-md {
    padding: 30px;
  }
}
