::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #929292;
}
::-webkit-scrollbar-thumb {
  background: #0ae4cf;
}
::-webkit-scrollbar-thumb:hover {
  background: #73f2e6;
}
@media (max-width: 860px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}
