/* START STYLE DEFAULT */
.style-default .session-content {
  font-family: Formula1;
  font-weight: 100;
}

.style-default .session-title {
  background: #2a2a2a;
  color: white;
  font-size: 17px;
  padding-bottom: 4px;
  border-top: 3px solid #00da94;
}

.style-default .gp-driver-laps-header {
  background: #2a2a2a;
  padding: 3px 0px;
}

.style-default .gp-session-header {
  background: #2a2a2a;
  color: white;
}

.style-default .session-content {
  background: #474747;
  color: white;
  padding-bottom: 3px;
}

.style-default .session-drivers .gp-pos label {
  background: #f2f0f2;
  color: black;
  width: 23px;
  height: calc(100% - 3px);
  text-align: center;
  border-radius: 0px 0px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-default .gp-driver {
  position: relative;
  padding-left: 3px;
}

.style-default .bar-team {
  border-radius: 3px;
  width: 4px;
  height: 19px;
  position: absolute;
  left: -5px;
  top: 2px;
}

.style-default .session-drivers a {
  color: white;
}

.style-default .session-drivers .flag {
  right: 0px;
  position: absolute;
  top: 9px;
  width: 25px;
  height: 14px;
  max-width: 28px;
}

.style-default .gp-team {
  position: relative;
  padding-left: 10px;
}

.style-default .gp-driver-row label,
.style-default .gp-driver-row a {
  padding-top: 3px;
}
.style-default .gp-driver a {
  margin-top: -1px;
  position: absolute;
}

.style-default .gp-pos {
  padding-left: 3px;
}
.style-default .gp-driver-laps {
  width: 100%;
}

.style-default .team-logo-container {
  width: 21px;
  height: 21px;
  right: 5px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-default .team-logo-container img {
  max-width: 21px;
  max-height: 21px;
  width: auto;
}

.style-default .lvf1-driver {
  background: #0c875f;
}
.style-default .q2-eliminated label {
  background: #b0110a !important;
  color: white !important;
}
/* END STYLE DEFAULT */

/* START STYLE 2018-PRESENT */
.style-2018-Present .session-content {
  font-family: Formula1;
  font-weight: 100;
}

.style-2018-Present .session-title {
  background: #2a2a2a;
  color: white;
  font-size: 17px;
  padding-bottom: 4px;
  border-top: 3px solid #e1261d;
}

.style-2018-Present .gp-driver-laps-header {
  background: #2a2a2a;
  padding: 3px 0px;
}

.style-2018-Present .gp-session-header {
  background: #2a2a2a;
  color: white;
}

.style-2018-Present .session-content {
  background: #474747;
  color: white;
  padding-bottom: 3px;
}

.style-2018-Present .session-drivers .gp-pos label {
  background: #f2f0f2;
  color: black;
  width: 23px;
  height: 23px;
  text-align: center;
  border-radius: 0px 0px 5px 0px;
}

.style-2018-Present .gp-driver {
  position: relative;
  padding-left: 3px;
}

.style-2018-Present .bar-team {
  border-radius: 3px;
  width: 4px;
  height: 19px;
  position: absolute;
  left: -5px;
  top: 2px;
}

.style-2018-Present .session-drivers a {
  color: white;
}

.style-2018-Present .session-drivers .flag {
  right: 0px;
  position: absolute;
  top: 9px;
  width: 25px;
  height: 14px;
  max-width: 28px;
}

.style-2018-Present .gp-team {
  position: relative;
  padding-left: 10px;
}

.style-2018-Present .gp-driver-row label,
.style-2018-Present .gp-driver-row a {
  padding-top: 3px;
}
.style-2018-Present .gp-driver a {
  margin-top: -1px;
  position: absolute;
  padding-left: 2px;
}

.style-2018-Present .gp-pos {
  padding-left: 3px;
}
.style-2018-Present .gp-driver-laps {
  width: 100%;
}

.style-2018-Present .team-logo-container {
  width: 21px;
  height: 21px;
  right: 5px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-2018-Present .team-logo-container img {
  max-width: 21px;
  max-height: 21px;
  width: auto;
}

.style-2018-Present .lvf1-driver {
  background: #0c875f;
}

.style-2018-Present .q2-eliminated label {
  background: #b0110a !important;
  color: white !important;
}
/* END 2018-PRESENT STYLE */

/* START 2015-2017 STYLE */
.style-2015-2017 .session-content {
  font-family: Plateusa;
  background: #7b7b7b;
  font-size: 16px;
}
.style-2015-2017 .session-title {
  background: #2a2a2a;
  color: white;
}

.style-2015-2017 .gp-session-header {
  background: #2a2a2a;
  color: white;
}

.style-2015-2017 .gp-driver-row {
  color: white;
  padding-left: 3px;
  padding-bottom: 3px;
  margin-top: 3px;
}

.style-2015-2017 .gp-driver-laps-container:nth-child(2n) {
  background: #474747;
}
.style-2015-2017 .gp-driver-laps-container:nth-child(2n + 1) {
  background: #5a5a5a;
}

.style-2015-2017 .session-drivers .gp-pos {
  background: #a5a5a5;
  width: 41px;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
  margin-left: 5px;
  height: 25px;
  text-align: center;
}

.style-2015-2017 .session-drivers .gp-pos label {
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.style-2015-2017 .session-drivers a {
  color: white;
  padding-left: 18px;
}

.style-2015-2017 .bar-team {
  width: 8px;
  height: 25px;
  position: absolute;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
}

.style-2015-2017 .session-drivers .gp-driver {
  width: 237px !important;
  position: relative;
}

.style-2015-2017 .session-drivers .flag {
  right: 0px;
  position: absolute;
  top: 6px;
  width: 28px;
  max-width: 28px;
  height: 18px;
}

.style-2015-2017 .team-logo-container {
  display: none;
}

.style-2015-2017 .driver-penalty {
  color: #c7c7c7;
}

.style-2015-2017 .gp-driver-laps-header {
  background: #2a2a2a;
  color: white;
}
.style-2015-2017 .gp-driver-laps-times {
  color: white;
}

.style-2015-2017 .q2-eliminated {
  background: #ededed !important;
  color: #da0000;
}
/* END 2015-2017 STYLE*/

/* START 2010-2014 STYLE */
.style-2010-2014 .session-content {
  font-family: Plateusa;
  font-size: 16px;
}
.style-2010-2014 .session-title {
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 33%,
    rgb(187 180 180) 100%,
    rgb(61 61 61) 00%
  );
  text-align: center;
  color: black;
  font-weight: bold;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
  border-radius: 5px 5px 5px 5px;
  margin-left: 19px;
  width: calc(100% - 33px);
}

.style-2010-2014 .session-title label,
.style-2010-2014 .session-drivers label,
.style-2010-2014 .session-drivers a {
  display: inline-block;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.style-2010-2014 .gp-driver-row > div:not(.gp-detail),
.style-2010-2014 .gp-driver-laps-times > div {
  font-weight: 700;
  padding: 1px 0px 1px 0 !important;
  text-align: center;
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
  position: relative;
  border-radius: 5px 5px 5px 5px;
}

.style-2010-2014 .session-drivers a {
  color: white;
  position: absolute;
  left: 12px;
  text-align: left;
}

.style-2010-2014 .gp-driver-row label {
  color: #fff;
}

.style-2010-2014 .gp-driver-row .gp-team {
  background: rgb(60, 66, 80);
  background: -moz-linear-gradient(
    0deg,
    rgba(60, 66, 80, 1) 0%,
    rgba(60, 66, 80, 1) 33%,
    rgba(33, 37, 46, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(60, 66, 80, 1) 0%,
    rgba(60, 66, 80, 1) 33%,
    rgba(33, 37, 46, 1) 100%
  );
  background: -o-linear-gradient(
    0deg,
    rgba(60, 66, 80, 1) 0%,
    rgba(60, 66, 80, 1) 33%,
    rgba(33, 37, 46, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(60, 66, 80, 1) 0%,
    rgba(60, 66, 80, 1) 33%,
    rgba(33, 37, 46, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(60, 66, 80, 1) 0%,
    rgba(60, 66, 80, 1) 33%,
    rgba(33, 37, 46, 1) 100%
  );
  text-align: left !important;
}

.style-2010-2014 .gp-driver-row .gp-pos {
  background: rgba(181, 176, 175, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(181, 176, 175, 1) 0%,
    rgba(181, 176, 175, 1) 46%,
    rgba(120, 117, 116, 1) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(181, 176, 175, 1) 0%,
    rgba(181, 176, 175, 1) 46%,
    rgba(120, 117, 116, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(181, 176, 175, 1) 0%,
    rgba(181, 176, 175, 1) 46%,
    rgba(120, 117, 116, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(181, 176, 175, 1) 0%,
    rgba(181, 176, 175, 1) 46%,
    rgba(120, 117, 116, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(181, 176, 175, 1) 0%,
    rgba(181, 176, 175, 1) 46%,
    rgba(120, 117, 116, 1) 100%
  );
}

.style-2010-2014 .gp-driver-laps-container:first-child .gp-pos,
.style-2010-2014 .gp-driver-laps-container:first-child .gp-driver {
  background: rgba(201, 12, 12, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(201, 12, 12, 1) 0%,
    rgba(201, 12, 12, 1) 46%,
    rgba(84, 1, 1, 1) 80%,
    rgba(84, 1, 1, 1) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(201, 12, 12, 1) 0%,
    rgba(201, 12, 12, 1) 46%,
    rgba(84, 1, 1, 1) 80%,
    rgba(84, 1, 1, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(201, 12, 12, 1) 0%,
    rgba(201, 12, 12, 1) 46%,
    rgba(84, 1, 1, 1) 80%,
    rgba(84, 1, 1, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(201, 12, 12, 1) 0%,
    rgba(201, 12, 12, 1) 46%,
    rgba(84, 1, 1, 1) 80%,
    rgba(84, 1, 1, 1) 100%
  );
  background: linear-gradient(
    -45deg,
    rgba(201, 12, 12, 1) 0%,
    rgba(201, 12, 12, 1) 46%,
    rgba(84, 1, 1, 1) 80%,
    rgba(84, 1, 1, 1) 100%
  );
}

.style-2010-2014
  .gp-driver-row
  > div:not(.gp-detail, .gp-pos, .gp-team, .gp-tyre),
.style-2010-2014 .gp-driver-laps-times > div {
  background: rgba(201, 12, 12, 1);
  background: -moz-linear-gradient(
    4deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(121, 114, 114, 1) 100%
  );
  background: -webkit-linear-gradient(
    4deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(121, 114, 114, 1) 100%
  );
  background: -o-linear-gradient(
    4deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(121, 114, 114, 1) 100%
  );
  background: -ms-linear-gradient(
    4deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(121, 114, 114, 1) 100%
  );
  background: linear-gradient(
    4deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(121, 114, 114, 1) 100%
  );
}

.style-2010-2014 .team-logo-container,
.style-2010-2014 .session-drivers .bar-team {
  display: none;
}

.style-2010-2014 .gp-pos {
  border-radius: 5px 0px 0px 5px !important;
}
.style-2010-2014 .gp-driver {
  border-radius: 0px 5px 5px 0px !important;
  width: 230px;
  margin-right: 15px;
  overflow: hidden;
}

.style-2010-2014 .gp-team {
  width: 175px;
  margin-right: 15px;
}

.style-2010-2014 .gp-hotlap,
.style-2010-2014 .gp-gap {
  width: 120px;
  margin-right: 15px;
}

.style-2010-2014 .gp-sector {
  width: 80px;
  margin-right: 15px;
}

.style-2010-2014 .gp-driver-laps-times > div {
  margin-right: 15px;
}

.style-2010-2014 .driver-flag-container {
  position: absolute;
  right: -4px;
  height: 29px;
  width: 40px;
  top: -1px;
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 0) 105%
  );
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 0) 105%
  );
  -webkit-mask-size: 100% 50%;
  mask-size: 100% 50%;
  -webkit-mask-position: left top, left bottom;
  mask-position: left top, left bottom;
}

.style-2010-2014 .driver-flag-container img {
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
  height: 29px;
  min-width: 40px;
  right: -3px;
  position: absolute;
}

.style-2010-2014 .gp-tyre img {
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
  height: 24px;
  margin-left: -10px;
  position: absolute;
}

.style-2010-2014 .gp-tyres img {
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
  height: 24px;
}

.style-2010-2014 .gp-tyres {
  margin-right: 15px;
}

.style-2010-2014 .driver-penalty {
  color: #9f9f9f;
}

.style-2010-2014 .gp-driver-laps-times {
  color: white;
}

.style-2010-2014 .gp-time,
.style-2010-2014 .gp-laps,
.style-2010-2014 .gp-pitstops,
.style-2010-2014 .gp-laps-pitstops,
.style-2010-2014 .gp-points,
.style-2010-2014 .gp-balance {
  margin-right: 15px;
}

.style-2010-2014 .gp-balance svg,
.style-2010-2014 .gp-balance span {
  display: inline-block;
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.style-2010-2014 .gp-balance span {
  color: white;
}

.style-2010-2014 .balance-up {
  background: linear-gradient(180deg, #3eb044 2%, #25b72c 100%) !important;
}
.style-2010-2014 .balance-dw {
  background: linear-gradient(180deg, #fad422 2%, #c8a70f 100%) !important;
}
.style-2010-2014 .balance-up svg,
.style-2010-2014 .balance-up span,
.style-2010-2014 .balance-dw svg,
.style-2010-2014 .balance-dw span {
  color: black !important;
}

.style-2010-2014 .q2-eliminated {
  background: linear-gradient(
    135deg,
    rgb(239 239 239) 8%,
    rgb(251 241 241) 43%,
    rgb(229 229 229) 100%
  ) !important;
}
.style-2010-2014 .q2-eliminated label {
  color: #da0000;
}
.style-2010-2014 .gp-team a,
.style-2010-2014 .gp-team span {
  max-width: 160px;
}
/* END 2010-2014 STYLE */

/* START 2004-2009 STYLE */
.style-2004-2009 .session-content {
  font-family: Plateusa;
  font-size: 16px;
}
.style-2004-2009 .session-title {
  background: linear-gradient(
    180deg,
    rgb(238 238 238) 0%,
    rgba(255, 255, 255, 1) 85%,
    rgba(130, 120, 120, 1) 100%
  );
  color: black;
  font-weight: bold;
  text-align: center;
}

.style-2004-2009 .session-drivers .gp-driver {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 85%,
    rgba(130, 120, 120, 1) 100%
  );
  color: black;
}

.style-2004-2009 .gp-driver-row .gp-team,
.style-2004-2009 .gp-driver-row .gp-pos {
  background: linear-gradient(
    180deg,
    rgb(183 185 196) 0%,
    rgb(102 105 122) 85%,
    rgb(68 51 51) 100%
  );
  color: white;
  font-weight: bold;
}

.style-2004-2009 .session-drivers .gp-hotlap,
.style-2004-2009 .session-drivers .gp-gap,
.style-2004-2009 .session-drivers .gp-sector,
.style-2004-2009 .session-drivers .gp-pitstops,
.style-2004-2009 .session-drivers .gp-laps,
.style-2004-2009 .session-drivers .gp-laps-pitstops,
.style-2004-2009 .session-drivers .gp-points,
.style-2004-2009 .session-drivers .gp-balance {
  background: linear-gradient(
    180deg,
    rgb(52 49 49) 10%,
    rgba(0, 0, 0, 1) 100%,
    rgb(0 0 0) 100%
  );
  color: white;
}

.style-2004-2009 .gp-driver-laps-times > div {
  background: linear-gradient(
    180deg,
    rgb(52 49 49) 10%,
    rgba(0, 0, 0, 1) 100%,
    rgb(0 0 0) 100%
  );
}

.style-2004-2009 .gp-driver-laps-times > div {
  margin-right: 15px;
}

.style-2004-2009 .gp-team a,
.style-2004-2009 .gp-driver-laps-times {
  color: white;
}

.style-2004-2009 .gp-driver a {
  color: black;
}

.style-2004-2009 .gp-driver-laps-container:first-child .gp-pos {
  background: rgba(253, 0, 0, 1);
  background: -moz-linear-gradient(
    180deg,
    rgb(255 108 108) 0%,
    rgba(255, 0, 0, 1) 85%,
    rgb(253 0 0) 100%
  ) !important;
  background: -webkit-linear-gradient(
    180deg,
    rgb(255 108 108) 0%,
    rgba(255, 0, 0, 1) 85%,
    rgb(253 0 0) 100%
  ) !important;
  background: -o-linear-gradient(
    180deg,
    rgb(255 108 108) 0%,
    rgba(255, 0, 0, 1) 85%,
    rgb(253 0 0) 100%
  ) !important;
  background: -ms-linear-gradient(
    180deg,
    rgb(255 108 108) 0%,
    rgba(255, 0, 0, 1) 85%,
    rgb(253 0 0) 100%
  ) !important;
  background: linear-gradient(
    180deg,
    rgb(255 108 108) 0%,
    rgba(255, 0, 0, 1) 85%,
    rgb(253 0 0) 100%
  ) !important;
}

.style-2004-2009 .session-drivers .bar-team,
.style-2004-2009 .team-logo-container {
  display: none;
}

.style-2004-2009 .session-drivers .flag {
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
  border-radius: 0px;
  top: 3px;
  margin-right: 0px;
}
.style-2004-2009 .gp-pos {
  width: 28px;
  text-align: center;
}

.style-2004-2009 .session-drivers .gp-pos {
  padding-top: 2px;
}

.style-2004-2009 .session-drivers a {
  padding-left: 5px;
}

.style-2004-2009 .gp-driver {
  position: relative;
  width: 230px;
  margin-right: 10px;
}
.style-2004-2009 .gp-team {
  width: 180px;
  margin-left: 10px;
  margin-right: 10px;
}
.style-2004-2009 .gp-hotlap,
.style-2004-2009 .gp-gap {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.style-2004-2009 .gp-pitstops,
.style-2004-2009 .gp-laps,
.style-2004-2009 .gp-laps-pitstops,
.style-2004-2009 .gp-points {
  margin-left: 10px;
  margin-right: 10px;
}

.style-2004-2009 .gp-balance {
  margin-right: 20px;
}

.style-2004-2009 .gp-sector {
  width: 70px;
  margin-left: 10px;
  margin-right: 10px;
}

.style-2004-2009 .driver-penalty {
  color: #4e4e4e;
}

.style-2004-2009 .balance-up {
  background: linear-gradient(180deg, #45ff4e 2%, #25b72c 100%) !important;
  color: black !important;
}
.style-2004-2009 .balance-dw {
  background: linear-gradient(180deg, #fad422 2%, #c8a70f 100%) !important;
  color: black !important;
}
.style-2004-2009 .balance-up svg,
.style-2004-2009 .balance-dw svg {
  color: black !important;
}

.style-2004-2009 .q2-eliminated {
  background: linear-gradient(
    180deg,
    rgb(232 233 239) 0%,
    rgb(255 255 255) 85%,
    rgb(207 206 206) 100%
  ) !important;
}
.style-2004-2009 .q2-eliminated label {
  color: #da0000;
}
/* END 2004-2009 STYLE*/

/* START 1990S STYLE */
.style-1990s .session-content {
  background: #525252;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.style-1990s .session-title {
  background: #525252;
  color: #e6c929;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.style-1990s .session-content label,
.style-1990s .session-content a,
.style-1990s .gp-driver-laps label {
  text-shadow: 3px 2.4px 1px #000000;
}

.style-1990s .class_content {
  text-shadow: none !important;
}

.style-1990s .session-drivers .gp-hotlap,
.style-1990s .session-drivers .gp-gap,
.style-1990s .session-drivers .gp-sector {
  color: #e6c929;
}

.style-1990s .session-drivers .gp-pos label {
  background: #e6c929;
  color: black;
  width: 28px;
  height: 25px;
  text-align: center;
  font-weight: bold;
  text-shadow: none;
  font-size: 15px;
}

.style-1990s .session-drivers a {
  color: white;
}
.style-1990s .session-drivers .flag,
.style-1990s .session-drivers .bar-team,
.style-1990s .team-logo-container {
  display: none;
}

.style-1990s .driver-penalty {
  color: #9f9f9f;
}

.style-1990s .gp-driver-laps-header {
  background: #e6c929;
  color: black;
}
/* END 1990S STYLE*/
