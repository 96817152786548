#new-container {
  padding: 0px 20%;
  width: 100%;
}

#new-container h3 {
  text-align: justify;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}
#new-container small {
  text-align: left;
  display: block;
  opacity: 0.8;
  margin-bottom: 20px;
  text-align: center;
}
#new-container .img-container {
  text-align: center;
  width: 100%;
}
#new-container .img-container > img {
  max-width: 750px;
  margin-bottom: 10px;
  width: 100%;
  cursor: zoom-in !important;
}
#content-container {
  text-align: justify;
}

#content-container p {
  text-indent: 40px;
}
#content-container iframe,
#content-container img {
  max-width: 100%;
}

#content-container a {
  text-decoration: none;
}

#new-container .gp-time,
#new-container .gp-hotlap,
#new-container .gp-laps-pitstops,
#new-container .gp-laps {
  display: none;
}
.racenew-container {
  overflow: auto;
}
#new-container .session {
  min-height: 0px;
}
#new-container .gp-team {
  width: 130px;
}
#new-container .gp-driver {
  width: 200px;
}
#new-container .gp-driver-laps {
  font-size: 14px;
}
#new-container .dateFormat {
  text-align: right;
  width: 100%;
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
}

.news-bar > div {
  width: 25%;
}
.news-bar .news-item > div {
  margin-bottom: 0px !important;
}

.news-bar .news-item {
  height: 255px;
}

.news-bar > div h3 {
  font-size: 14px !important;
}

.carousel-content {
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.carousel-content h4 {
  border-top-right-radius: 10px;
  border-top-left-radius: 5px;
}

.prev-button {
  left: -5px;
}

.next-button {
  right: -5px;
}

.prev-button,
.next-button {
  position: absolute;
  top: 130px;
  background: rgba(58, 58, 58, 0.8);
  padding: 7px;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.carousel-content {
  border-bottom-right-radius: 10px;
  margin-top: 40px;
}
.news-bar {
  margin-top: -8px;
}
.carousel-content h4 {
  font-size: 18px;
  padding: 7px;
  font-weight: bold;
}

#modal-news-image img {
  max-width: 100%;
}

#modal-news-image .modal-body {
  text-align: center;
}

#modal-news-image {
  max-width: 90vw;
  width: auto;
}

#modal-news-image .modal-content {
  width: 100%;
}
#modal-news-image img {
  max-height: calc(100vh - 150px);
}

#new-container .img-container img,
#new-container #content-container img {
  transition: filter 0.3s, transform 0.3s;
}

#new-container .img-container img:hover,
#new-container #content-container img:hover {
  filter: brightness(90%);
  cursor: pointer;
  transform: scale(1.02);
}

#detailed-container {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

#new-container #detailed-results a {
  color: white !important;
  text-decoration: none;
}

#new-container #detailed-results svg {
  margin-right: 5px;
}

.carousel-content .news-item {
  height: 255px;
}

.carousel-content .texto {
  font-size: 18px !important;
}

.slick-prev {
  left: -15px !important;
  top: 100px !important;
  z-index: 1;
}
.slick-next {
  right: -5px !important;
  top: 100px !important;
  z-index: 1;
}

.slick-next::before,
.slick-prev::before {
  opacity: 1 !important;
  font-size: 32px !important;
  color: #179387 !important;
}

.slick-slider {
  margin-top: -12px;
}

/* MED SCREEN STYLES */
@media (max-width: 1470px) {
  #new-container {
    padding: 0px 10%;
  }
}

/* SMALL SCREEN STYLES */
@media (max-width: 900px) {
  #new-container {
    padding: 0px 20px;
  }

  #new-inner-container #content-container p img {
    width: 100%;
    float: left;
  }
}

/* MOBILE SCREEN STYLES */
@media (max-width: 580px) {
  #new-container {
    padding: 0px 0px;
  }
}
