.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 6px 6px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.best-times-standings .tyre-image {
  max-height: 20px;
}

.best-times-standings .rdt_TableCell:nth-child(2) > div,
.livetiming-table-container .rdt_TableCell:nth-child(2) > div {
  width: 100%;
}

.best-times-standings {
  margin: 0 auto;
}

.react-resizable .button-red {
  display: none;
}

.react-resizable:first-child {
  margin-right: 15px;
}

.live-weather {
  margin-left: 5px;
}

.livetiming-table-container svg {
  margin-left: 15px;
}

.rain-data {
  margin-right: 10px;
}

.livetiming-table-container .tyre-image {
  max-height: 20px;
}

.livetiming-table-container > div {
  padding: 8px;
}

.livetiming-table-container > div > div {
  padding-bottom: 8px;
  z-index: 1;
}

#fastest-lap-container {
  overflow: auto;
  padding-bottom: 5px;
}

#live-timing-page .secondary-title {
  margin-bottom: 0px;
}

#circuit-livetiming-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.svg g circle,
.svg g use {
  transition: transform 1000ms linear;
}

.svg defs text {
  transform: rotate(0deg);
}
.svg {
  overflow: inherit;
  position: absolute;
  overflow: visible;
  overflow: auto;
}

.logo-results-container .flag {
  margin-right: 0px;
  margin-top: -1px;
}

#live-timing-page .secondary-title {
  height: 35px;
}

.btn-record-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background: rgb(255, 238, 238) !important;
  color: red;
  border: 1px solid red !important;
  font-size: 12px;
  padding: 0px 5px !important;
}
.btn-record-button:hover {
  color: rgb(255, 221, 221);
  background: rgb(255, 58, 58) !important;
}

.btn-record-button svg {
  margin-right: 3px;
  font-size: 11px;
}

.control-panel {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: #f1f1f1;
  color: black;
  border-radius: 5px;
  z-index: 1;
}

.control-panel > div:first-child {
  width: 50%;
  min-width: 435px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}

.control-panel svg {
  cursor: pointer;
}

.hcDT .rdt_TableRow > div:nth-child(3) {
  padding: 0px !important;
}

.s1-small,
.s2-small,
.s3-small,
.ret-small,
.fin-small {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 3px;
}
.s1-small {
  background: #5ba60e;
  color: white !important;
}
.s2-small {
  background: #a81643;
  color: white !important;
}
.s3-small {
  background: #177cbc;
  color: white !important;
}
.ret-small {
  background: #6a1b9a;
  color: white !important;
}
.fin-small {
  background: white;
  padding-top: 0px;
}
.fin-small img {
  height: 20px;
}

.speed-backward {
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: -18px;
}
.speed-forward {
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: -18px;
}
.control-panel > div:first-child > div {
  position: relative;
}

.livetiming-race-container {
  position: relative;
}

.timeline-container {
  position: relative;
  margin-top: 5px;
  height: 25px;
  display: flex;
  justify-content: center;
}

.timeline-container > div {
  width: 100%;
}

.css-3g5hux-Slider {
  background-color: #3bd8c9 !important;
}

#label-107 {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

#label-107 > label {
  min-width: 20%;
}
.class_livetiming {
  font-size: 10px;
  padding: 3px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

#ac-recorder-button svg {
  color: red;
}

#ac-recorder-button {
  margin-top: 41px;
  font-size: 12px;
}

.dropdown-container-lt.open {
  z-index: 2;
}

.dropdown-container-lt {
  position: absolute;
  top: 0;
  cursor: pointer;
  left: -5px;
  min-width: 150px;
}

.dropdown-menu-lt a {
  padding: 5px;
  transition: background-color 0.3s ease;
}

.dropdown-menu-lt {
  z-index: 1;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.dropdown-menu-lt.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  z-index: 2;
}

#drag-zone {
  width: calc(100% - 35%);
}
#qualy-results {
  width: 35%;
}
.container-qres {
  font-size: 12px;
  width: 250px;
}
.container-qres > div div:first-child {
  width: 20%;
}
.container-qres > div div:nth-child(2) {
  width: 60%;
}
.container-qres > div div:last-child {
  width: 20%;
}
#drag-zone {
  padding-right: 20px;
}
#drag-zone span {
  font-size: 12px;
  padding: 2px !important;
  transition: background-color 0.3s ease;
  border: 1px solid #666666 !important;
}

.modal-sort-grid {
  display: flex;
}

@media (max-width: 1100px) {
  #live-timing-page > div {
    width: 100% !important;
    margin-bottom: 15px;
  }
  #live-timing-page {
    display: block;
    overflow-x: hidden;
  }
  #circuit-livetiming-container {
    width: 100% !important;
    height: 275px !important;
    display: block;
  }
  #circuit-livetiming-container .mycanvas-container,
  #circuit-livetiming-container .mycanvas {
    height: 275px;
  }
  /*#circuit-livetiming-container .svg {
    height: 280px;
  }*/

  #live-timing-page > div:first-child {
    height: 320px !important;
  }
  .livetiming-table-container {
    height: auto !important;
  }
  .react-resizable-handle {
    display: none;
  }

  #drag-zone {
    width: 100%;
  }
  #qualy-results {
    width: 100%;
  }
  .modal-sort-grid {
    display: block;
  }
}
