.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 20px 0 80px;
}

.card,
.text-card {
  margin: 10px;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  border: 0px;
}
.text-card {
  padding: 10px;
}
.text-card p {
  margin: 0px;
}
.card:hover .card__caption {
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
}

.card:hover .card__thumb::after {
  top: 0;
}

.card:hover .card__snippet {
  margin: 20px 0;
}

.card__thumb {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .card__thumb {
    max-height: 500px;
  }
}

.card__thumb::after {
  position: absolute;
  top: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}

@media (min-width: 1024px) {
  .card__thumb::after {
    top: calc(100% - 140px);
  }
}

.card__image {
  transition: 0.5s ease-in-out;
  width: 100%;
}

.card__caption {
  position: absolute;
  top: 50%;
  z-index: 1;
  padding: 0 20px;
  color: white;
  transform: translateY(-50%);
  text-align: center;
  transition: 0.3s;
  padding: 15px;
}

@media (min-width: 1024px) {
  .card__caption {
    top: calc(100% - 100px);
    transform: unset;
  }
}

.card__title {
  display: -webkit-box;
  max-height: 85px;
  overflow: hidden;
  font-size: 23px;
  line-height: 28px;
  text-shadow: 0px 1px 5px black;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: bold;
}

.card__snippet {
  display: -webkit-box;
  max-height: 150px;
  margin: 20px 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  transition: 0.5s ease-in-out;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

@media (min-width: 1024px) {
  .card__snippet {
    margin: 60px 0;
  }
}

.card__button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
}

.card__button:hover {
  color: black;
  background-color: white;
}

.paper {
  --paper-dark: #e5c93d;
  --paper-color: #ffed87;
  color: black;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  background: linear-gradient(
    135deg,
    var(--paper-dark),
    30%,
    var(--paper-color)
  );
  box-shadow: 3px 3px 2px var(--paper-shadow);
  transform: rotate(359deg);
  transform-origin: top left;
  width: calc(100% - 50px);
  margin-bottom: 40px;
}
.paper:hover {
  animation: blinker 0.5s linear;
}

.paper a {
  color: rgb(23, 147, 135) !important;
}

@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}

.paper-container {
  display: flex;
  justify-content: center;
}

.paper p {
  margin: auto;
}

.pin {
  --pin-color: #d02627;
  --pin-dark: #9e0608;
  --pin-light: #fc7e7d;

  position: absolute;
  left: 20px;
  width: 60px;
  height: 50px;
  top: -27px;
  transition: top 0.3s, left 0.3s;
}

.pins-container div:nth-child(2n) .pin {
  left: auto;
  right: 0;
}

.pins-container div:nth-child(2n) .paper {
  transform: rotate(1deg);
  margin-top: 0px;
}

.shadow {
  position: absolute;
  top: 18px;
  left: -8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: radial-gradient(var(--paper-shadow), 20%, rgba(201, 191, 141, 0));
}

.metal {
  position: absolute;
  width: 5px;
  height: 20px;
  background: linear-gradient(to right, #808080, 40%, #eae8e8, 50%, #808080);
  border-radius: 0 0 30% 30%;
  transform: rotate(50deg);
  transform-origin: bottom left;
  top: 15px;
  border-bottom: 1px solid #808080;
}

.bottom-circle {
  position: absolute;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--pin-color);
  background: radial-gradient(
    circle at bottom right,
    var(--pin-light),
    25%,
    var(--pin-dark),
    90%,
    var(--pin-color)
  );
}

/* Barrel */
.bottom-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 20px;
  height: 30px;
  transform: rotate(55deg);
  transform-origin: 100% 100%;
  border-radius: 0 0 40% 40%;
  background: linear-gradient(
    to right,
    var(--pin-dark),
    30%,
    var(--pin-color),
    90%,
    var(--pin-light)
  );
}

/* Top circle */
.bottom-circle::after {
  content: "";
  position: absolute;
  right: -10px;
  top: -5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(
    circle at right,
    var(--pin-light),
    30%,
    var(--pin-color),
    var(--pin-dark) 80%
  );
}

.dyimages-container {
  width: 70%;
  float: left;
}

.pins-container {
  width: 30%;
  float: right;
}
.dyimages-container {
  display: flex;
  flex-wrap: wrap;
}
.dyimages-container > div {
  width: calc(50% - 30px);
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  60% {
    -webkit-transform: translateY(-7.5px);
    -ms-transform: translateY(-7.5px);
    transform: translateY(-7.5px);
  }
}
.paper-container .pin {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.paper-container:hover .pin {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

@media (max-width: 1760px) {
  .paper {
    width: calc(100% - 10px);
  }
}

@media (max-width: 1630px) {
  .paper p {
    font-size: 14px;
  }
}

@media (max-width: 1260px) {
  .dyimages-container,
  .pins-container {
    width: 100%;
  }
  .pins-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
  }
  .paper-container {
    width: calc(50% - 15px);
  }
  .pins-container > div:nth-child(2n) {
    margin-left: 7.5px;
  }
  .pins-container > div:nth-child(2n + 1) {
    margin-right: 7.5px;
  }
  .dyimages-container > div {
    width: calc(50% - 20px);
  }
  .paper {
    transform: rotate(0deg) !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 970px) {
  .card {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .paper-container {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .card__title {
    font-size: 16px;
  }
  .card__snippet {
    font-size: 12px;
    line-height: 13px;
  }
  .card__button {
    display: none;
  }
}
