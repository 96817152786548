.main-title svg {
  float: left;
  cursor: pointer;
  margin-top: 8px;
}

.spinner-button {
  font-size: 32px;
}

#penalty-table th:first-child {
  width: 30px;
}

#penalty-table th:nth-child(2),
#penalty-table th:nth-child(4) {
  width: 100px;
}

#penalty-table th:last-child {
  width: 20%;
}

#admin-container > div label {
  font-weight: bold;
  font-size: 4vh;
  width: 100%;
  text-align: center;
}
#admin-container > div a {
  width: 100%;
  display: block;
  text-align: center;
  padding: 2vh 0px;
  text-decoration: none;
  font-size: 16px;
  color: white;
  transition: 0.2s;
}
#admin-container > div a:hover {
  background: #4b4b4b;
}

.race-upd-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.trash-container svg {
  font-size: 22px;
  margin-top: 35px;
  cursor: pointer;
}

.team-image-container {
  width: 250px;
}

.team-select-container {
  width: calc(100% - 250px);
}

#admin-titular-driver-container img {
  max-height: 70px;
  max-width: 250px;
}

#admin-titular-driver-container b {
  width: 50px;
  text-align: center;
  padding-top: 7px;
  margin-right: 3px;
}

#admin-titular-driver-container select {
  border: 0px !important;
}

#admin-titular-driver-container > div,
.team-select-container > div {
  margin-bottom: 3px;
}

.label-assignments {
  width: 170px !important;
  padding-right: 8px;
  text-align: right;
}

.races-group label {
  margin-right: 10px;
  margin-top: 6px;
  width: 120px;
}

.scoring-group {
  width: 115px;
}

.draggable-icon {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 34px;
  cursor: pointer;
}

.trash-icon {
  margin-top: 36px;
  margin-left: 20px;
  font-size: 26px;
  cursor: pointer;
}

.create-button {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 28px;
  cursor: pointer;
}

.icon-container {
  padding-top: 32px;
  font-size: 20px;
}

.driverphoto-request {
  border: 1px solid grey;
  text-align: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  position: relative;
}
.driverphoto-request svg.fa-arrow-right {
  font-size: 34px;
  margin: 0px 30px;
}

.requests-buttons {
  position: absolute;
  right: 10px;
  top: 70px;
  display: grid;
}
.requests-buttons svg {
  font-size: 48px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.copy-to-english-btn {
  float: right;
  height: 30px;
  padding: 1px 8px !important;
}

.no-width-limit {
  width: auto !important;
  max-width: max-content !important;
}

.delete-form-icon {
  margin-left: 10px;
  font-size: 18px;
  margin-top: 8px;
}

.form-container .main-title {
  font-size: 26px;
}

.basel {
  align-items: baseline;
}
#admin-container {
  width: 100%;
}
#admin-container > div {
  width: calc(16.66% - 10px);
  margin: 0px 5px;
  background-color: rgb(46, 46, 46);
  border-radius: 5px;
  padding: 0px;
}

#admin-container > div label {
  font-size: 26px;
  background: #3bd8c9;
  color: black;
  border-radius: 5px 5px 0px 0px;
}

.form-titlerow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.custom-error {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

@media (max-width: 1220px) {
  #admin-container > div {
    width: calc(33.33% - 10px);
    margin-bottom: 30px;
  }
  .form-container {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media (max-width: 760px) {
  #admin-container > div {
    width: calc(50% - 10px);
    margin-bottom: 30px;
  }
}

@media (max-width: 520px) {
  #admin-container > div {
    width: calc(100% - 10px);
    margin-bottom: 30px;
  }
}
