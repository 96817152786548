.gp-pos {
  width: 35px;
}
.gp-balance {
  width: 40px;
}
.gp-driver {
  width: 250px;
}
.gp-team {
  width: 200px;
}
.gp-hotlap,
.gp-time {
  width: 120px;
}
.gp-gap {
  width: 120px;
}
.gp-sector,
.gp-laps,
.gp-pitstops,
.gp-points {
  width: 80px;
}
.gp-sector {
  width: 80px;
}
.gp-heats {
  width: 100px;
}
.gp-tyre {
  width: 43px;
}
.gp-detail {
  width: 50px;
}
.gp-laps-pitstops,
.gp-tyres {
  width: 180px;
}
.tyre-image {
  width: 100%;
}
.session-title {
  font-weight: bold;
}
.session-content {
  font-size: 13px;
  font-weight: bold;
}
.gp-session-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}
.session-drivers a {
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 35px);
}
.gp-tyre img,
.gp-tyres img {
  max-height: 25px;
  width: 25px;
}
.gp-driver-laps-header {
  width: 100%;
}

.gp-driver-laps-pos {
  width: 7%;
}

.gp-driver-laps-lap,
.gp-driver-laps-time {
  width: 50%;
}

.gp-driver-laps-gap,
.gp-driver-laps-sector {
  width: 23%;
}

.gp-driver-laps-tyres,
.gp-driver-laps-fuel {
  width: 40%;
}

.gp-driver-laps-tyres span {
  font-size: 8px;
  position: absolute;
}

.gp-driver-laps-tyres img {
  position: absolute;
  height: 16px;
}

.front-compound,
.rear-compound {
  width: 10px;
  height: 10px !important;
  position: absolute;
}

.front-compound {
  margin-left: 20px;
}

.rear-compound {
  margin-left: -25px;
}

.gp-tyre-rl,
.gp-tyre-rr {
  margin-left: -70px;
}

.gp-tyre-rl,
.gp-tyre-fl {
  margin-top: -9px;
}

.gp-tyre-rr,
.gp-tyre-fr {
  margin-top: 9px;
}

.gp-tyre-fl,
.gp-tyre-fr {
  margin-left: 70px;
}

.gp-driver-laps-fuel {
  position: relative;
}
.bar-fuel {
  width: 100%;
  height: 20px;
  position: absolute;
  left: 0;
}
.gp-driver-laps-fuel span {
  z-index: 1;
  color: white !important;
}
.session {
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: calc(100vh - 215px);
  margin-bottom: 30px;
}

.gp-detail svg {
  font-size: 16px;
  cursor: pointer;
  margin-top: 3px;
}

.gp-driver-laps-fuel label {
  color: white;
  z-index: 1;
}

.driver-penalty {
  font-size: 10px;
}

.gp-driver-laps {
  overflow: hidden;
}

.wear-container {
  position: relative;
  width: 100%;
  height: 21.5px;
}

.gp-driver-laps-times {
  height: 21.5px;
}
.gp-driver-laps-header {
  height: 25.5px;
}

.session-drivers .gp-team {
  display: flex;
}

.gp-pitstops > span {
  width: 30px;
}

.gp-pitstops > div {
  max-width: 250px;
}
.gp-laps-pitstops .penalty {
  color: rgb(216, 78, 78);
}

.gp-balance svg {
  margin-right: 2px;
  float: left;
  margin-top: 7px;
  margin-left: 4px;
  font-size: 12px;
}
.gp-balance span {
  margin-top: 2px;
  display: block;
}

.gp-laps-pitstops label {
  width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.gp-laps-pitstops span {
  margin-right: 4px;
}

.fa-angle-up {
  color: rgb(101, 241, 101);
}
.fa-angle-down {
  color: rgb(235, 92, 92);
}

.driver-penalty {
  opacity: 0.5;
}
.gp-tyres {
  overflow: hidden;
}

.gp-driver > span,
.gp-team > span {
  opacity: 0.7;
  cursor: context-menu;
  height: 22.5px;
  display: block;
  padding-top: 2px;
}
.gp-driver-laps-header {
  cursor: context-menu;
}

.gp-team a,
.gp-team span {
  margin-right: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 179px;
}

.session-drivers .gp-pos.retired {
  opacity: 0;
}

.gp-laps-pitstops label {
  padding: 0px 3px;
}

.no-second-padding .rdt_TableRow > div:nth-child(2) {
  padding-left: 16px;
  padding-right: 16px;
}

#drivers-licenses .rdt_TableCell {
  padding: 5px;
}

#drivers-licenses .rdt_TableCell:first-child {
  display: block !important;
  text-align: left;
}

.class_content {
  padding: 3px;
  font-size: 9px;
  border-radius: 5px;
  color: black;
}

.gp-driver-container {
  display: block;
  height: 25px;
  position: relative;
}

#new-inner-container .gp-driver-laps {
  font-size: 12px !important;
}

@media (max-width: 600px) {
  .gp-driver-laps {
    font-size: 12px !important;
  }
}
