.team-image-td {
  text-align: center;
}
.team-image-td {
  width: 25%;
}
.team-image-td img {
  width: 80%;
  transition: width 0.5s;
}
.driver-number {
  min-width: 30px;
  text-align: center;
  margin-right: 5px;
  height: 24px;
}

.margin-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.team-image-td img:hover {
  width: 100%;
}

#download-row-container {
  margin-top: 25px;
  text-align: center;
}

.download-row {
  margin-bottom: 15px;
  width: 450px;
  border-bottom: 2px solid #1dc9b8;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.download-row > div {
  width: 150px;
}

#entries-circuit-container {
  margin: 10px auto;
}

#homecarousel-container .rain-data {
  display: none;
}

@media (max-width: 1220px) {
  #team-drivers-container {
    width: 100%;
    overflow: auto;
  }
}

@media (max-width: 762px) {
  #team-drivers-table th:nth-child(2),
  #team-drivers-table td:nth-child(2),
  #team-drivers-table th:nth-child(3),
  #team-drivers-table td:nth-child(3) {
    display: none;
  }
  #team-drivers-table {
    width: 100%;
  }
  #download-row-container {
    overflow: auto;
  }
}
