#update-profile-container,
.form-container {
  max-width: 800px;
  width: 50%;
}
.mandatory-fields {
  text-align: center;
  display: block;
  margin-top: -21px;
  margin-bottom: 16px;
}

.file-container > label:first-child {
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.cropper-container2 {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
}

#update-profile-container small {
  margin-top: 0px;
}
.reset-pwd {
  width: 80%;
  display: flex;
  justify-content: center;
}
.form-resetpwd button {
  margin-top: 15px;
}
@media (max-width: 762px) {
  #update-profile-container {
    max-width: 800px;
    width: calc(100% - 15px);
    padding: 5px;
  }
  #update-profile-container button {
    margin-bottom: 15px;
  }
}
