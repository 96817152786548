#seasons-container,
#nationalities-container {
  width: 100%;
}
.race-image-map {
  margin-bottom: 30px;
}
.race-image-map h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  text-align: center;
}
.race-image-map img {
  max-height: 200px;
  width: 100%;
}
.map-chart {
  margin-bottom: 80px;
}
.image-gallery-description {
  width: 100%;
  bottom: 0px !important;
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
}

#nationalities-container .rdt_TableCell:nth-child(2),
.historic-modal .rdt_TableCell:nth-child(2) {
  justify-content: left;
  padding: 3px;
}

#carousel-container {
  /*overflow: hidden;*/
  margin-bottom: 60px;
  height: calc(100vh - 120px);
}

.image-gallery-content:not(.fullscreen)
  .image-gallery-slide
  .image-gallery-image {
  max-height: calc(100vh - 330px) !important;
}

#race-selector-container {
  margin-bottom: 20px;
}

#race-selector-container > div {
  width: 30%;
}

#race-selector-container span {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

#race-selector-container > div:first-child {
  margin-right: 7px;
}

#race-selector-container > div:last-child {
  margin-left: 7px;
}

#race-image-loading > div {
  min-height: calc(100vh - 200px);
}

.didyouknow-card,
.didyouknow-card-type2 {
  margin-bottom: 15px;
}
.didyouknow-card img {
  width: 100%;
  max-height: 280px;
}

.didyouknow-card .backgrounded-title {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
}

.didyouknow-card p,
.didyouknow-card-type2 p {
  margin-bottom: 0px;
  padding: 5px 10px;
  position: inherit;
}

.didyouknow-type2 {
  border: 4px solid;
}

.didyouknow-type2 p {
  height: 120px;
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 1600px) {
  .padding-lg {
    padding: 15px 5%;
  }
}

@media (max-width: 860px) {
  .padding-lg {
    padding: 15px 15px;
  }
  #seasons-chart-container,
  #attendance-chart-container {
    overflow: auto;
  }
  #seasons-chart-container > div {
    width: 800px !important;
    height: 300px !important;
  }
  #attendance-chart-container > div {
    width: 800px !important;
  }
  #carousel-container {
    height: auto;
  }
}

@media (max-width: 990px) {
  .didyouknow-card img {
    max-height: 100%;
  }
}
