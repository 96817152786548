.rdt_TableCell {
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.driver-image {
  width: 240px;
}

.driver-summary {
  margin-left: 15px;
  overflow: auto;
}

.driver-summary-table-container,
.driver-results-table-container {
  min-width: 1000px;
}

.driver-summary-table-container > div {
  overflow: hidden;
  max-height: 288px;
  overflow-y: auto;
}

.driver-summary-table-container .rdt_TableRow:last-child .rdt_TableCell {
  font-weight: bold;
}

.driver-summary .rdt_TableRow > div:nth-child(2),
.driver-summary .rdt_TableRow > div:last-child,
.driver-results .rdt_TableRow > div:nth-child(3) {
  display: block !important;
}

.driver-summary .rdt_TableRow > div:last-child > div,
.driver-results .rdt_TableRow > div:last-child > div,
.driver-results .rdt_TableRow > div:nth-last-child(2) > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.driver-summary .rdt_TableRow > div:last-child > div > div,
.driver-results .rdt_TableRow > div:last-child > div > div,
.driver-results .rdt_TableRow > div:nth-last-child(2) > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.driver-summary .rdt_TableCol:last-child,
.driver-summary .rdt_TableCell:last-child {
  padding: 0 !important;
}
.driver-results div,
.driver-results a,
.driver-summary div,
.driver-summary a {
  font-size: 12.5px;
}

.driver-summary .teamdriver-results-container {
  height: 15px;
  padding-left: 30px;
}
.driver-summary .logo-results-container {
  height: 15px;
  width: 15px;
}

.rdt_TableHeader {
  display: none !important;
}
/*
.driver-summary-table-container {
  max-height: 288px;
}*/

.rdt_TableBody {
  display: block !important;
}

.driver-results-table-container > div > div {
  width: auto;
}

.driver-results .rdt_TableCell,
.driver-results .rdt_TableCol {
  padding: 0px !important;
}
.driver-results .standings-results {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.driver-results-gp {
  display: flex;
  flex-direction: column;
}
.driver-results .teamdriver-results-container {
  height: 30px;
}

.driver-results .teamdriver-results-container .logo-results-container {
  height: 30px;
}

.driver-results .standings-results span {
  font-size: 11px;
}

.driver-results .rdt_TableCell > div > div:not(:last-child) {
  border-bottom: 1px solid;
}

.driver-results
  .rdt_TableCol:nth-child(n + 3):not(:nth-last-child(2)):not(:last-child)
  > div {
  display: grid;
}

.driver-results .teamdriver-results-container a,
.driver-results .teamdriver-results-container label,
.driver-results .teamdriver-results-container {
  white-space: normal !important;
}
.driver-results .teamdriver-results-container a,
.driver-results .teamdriver-results-container label {
  height: 30px;
  width: 109px;
  display: table-cell !important;
  vertical-align: middle;
  line-height: 1.1;
  font-size: 13px;
}
.driver-results .rdt_TableRow > div:last-child,
.driver-results .rdt_TableRow > div:nth-last-child(2),
.driver-summary .rdt_TableRow > div:last-child {
  font-weight: bold;
}

.results-title {
  margin-top: 10px;
}

.events-container > div {
  border: 1px solid;
  border-radius: 5px;
  padding: 7px;
  margin-right: 10px;
}
.events-container span {
  width: 100% !important;
  display: block;
  font-size: 12px;
  text-align: center;
}

.event-pos label {
  margin-left: 8px;
}
.event-pos {
  font-size: 18px !important;
  font-weight: bold;
  width: 35px !important;
}

.driver-photo {
  width: 218px;
  height: 288px;
}

.class-label {
  padding: 5px;
  font-size: 14px;
  color: black;
}

.team-container {
  height: 50px;
}

.team-container img {
  height: 50px;
  width: auto;
}

.events-team {
  font-weight: bold;
  background: linear-gradient(270deg, rgb(144, 0, 211), rgb(241, 91, 209));
  background-size: 400% 400%;
  animation: gradient-animation 2s infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 766px) {
  #photo-summary-container {
    display: block;
  }
  .driver-summary {
    margin-left: 0px;
  }
  #photo-summary-container > div {
    text-align: center;
    margin-bottom: 15px;
  }
  .h3-driver-name {
    text-align: center;
  }
  .driver-results {
    overflow: auto;
  }
  .driver-results-table-container {
    min-width: 0px;
  }
}
